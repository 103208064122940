import React, { useEffect } from "react";
import "../../../block.css";
import "./contacts-map-1.css";

import classNames from "classnames";

import Map from "../../../../components/Map/Map";
import InstagramWidget from "../../../../components/InstagramWidget/InstagramWidget";

import photo from "./files/office-photo.jpg";
import instIcon from "./files/inst-icon.svg";
import vkIcon from "./files/vk-icon.svg";

export default function ContactsMap1({
  className,
  instUserData,
  ...otherProps
}) {
  function map(mapOptions) {
    mapOptions.map.center = [53.232120, 50.190977];
    mapOptions.map.zoom = 14;

    var myMap = new window.ymaps.Map("map", mapOptions.map);

    myMap.geoObjects.add(
      new window.ymaps.Placemark(
        [53.232120, 50.190977],
        {},
        mapOptions.placeMark
      )
    );
  }

  return (
    <div
      className={classNames("block contacts-map-1", className)}
      {...otherProps}
    >
      <div className="block__content_column contacts-map-1__content">
        <h2 className="contacts-map-1__header">Контакты</h2>

        <div className="contacts-map-1__location">
          <div className="contacts-map-1__photo-wrapper">
            <img className="contacts-map-1__photo" src={photo} alt="Office" />
          </div>

          <div className="contacts-map-1__map">
            <Map initMap={map} />
          </div>
        </div>

        <div className="contacts-map-1__contacts">
          <div className="contacts-map-1__info-wrapper-1">
            <div className="contacts-map-1__info">
              <h4 className="contacts-map-1__info-header">Адреса</h4>

              <p className="contacts-map-1__info-text text_body">
                <b>Офис: </b>
                <br />
                Самара, ул. Советской Армии,
                <br />
                д. 221, БЦ "Луч", оф. 304
              </p>

              <p className="contacts-map-1__info-text text_body">
                Время работы:
                <br />
                Пн-Пт с 9:00 до 18:00
              </p>

              <p className="contacts-map-1__info-text text_body">
                <b>Производство: </b>
                <br />
                г. Самара, ул. Советской Армии, д. 221, БЦ Луч, цокольный этаж
              </p>
            </div>
          </div>

          <div className="contacts-map-1__info-wrapper-2">
            <div className="contacts-map-1__info">
              <h4 className="contacts-map-1__info-header">Почта</h4>

              <p className="contacts-map-1__info-text text_body">
                <a href="mailto:indigo-samara@yandex.ru">
                  indigo-samara@yandex.ru
                </a>
              </p>

              <a
                className="contacts-map-1__social"
                href="https://vk.com/indigo_v_samare"
                target="_blank"
              >
                <img src={vkIcon} />
                Вконтакте
              </a>

              <a
                className="contacts-map-1__social"
                href="https://instagram.com/indigoprintsamara"
                target="_blank"
              >
                <img src={instIcon} />
                Инстаграм
              </a>
            </div>

            <div className="contacts-map-1__info">
              <h4 className="contacts-map-1__info-header">Телефон</h4>
              <p className="contacts-map-1__info-text text_body">
                Любовь, менеджер
                <br />
                <a href="tel:+79272980291">+7 927 298 02 91</a>
              </p>
              <p className="contacts-map-1__info-text text_body">
                Елена, менеджер
                <br />
                <a href="tel:+79276118977">+7 927 611 89 77</a>
              </p>
              <p className="contacts-map-1__info-text text_body">
                Наталья, менеджер
                <br />
                <a href="tel:+79276909870">+7 927 690 98 70</a>
              </p>
              <p className="contacts-map-1__info-text text_body">
                Сергей, директор
                <br />
                <a href="tel:+79276556346">+7 927 655 63 46</a>
              </p>
              <p className="contacts-map-1__info-text text_body">
                Телефоны офиса
                <br />
                <a href="tel:+78463211551">+7 846 321 15 51</a>
                <br />
                <a href="tel:+78463210626">+7 846 321 06 26</a>
                <br />
                <a href="tel:+79372125626">+7 937 212 56 26</a>
              </p>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}
