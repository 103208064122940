import React from "react";
import "../../menu.css";
import "./menu-full-screen-2.css";

import classNames from "classnames";
import { Transition } from "react-spring/renderprops";
import { Link } from "react-router-dom";

import closeIcon from "../../../../files/icons/close-icon.svg";
import instIcon from "../../../../blocks/header/4/files/inst-icon.svg";
import vkIcon from "../../../../blocks/header/4/files/vk-icon.svg";
import logo from "./files/logo.png";
import bg from "../../../../files/background.png";

function MenuButton({ children, className, ...otherProps }) {
  return (
    <Link
      className={classNames("menu__link menu-f-scr-2__button", className)}
      {...otherProps}
    >
      {children}
    </Link>
  );
}

function MenuLink({ children, className, ...otherProps }) {
  return (
    <a
      className={classNames("menu__link menu-f-scr-2__link", className)}
      {...otherProps}
    >
      {children}
    </a>
  );
}

export default function MenuFullScreen({
  className,
  menuOpened,
  setMobMenuOpened,
  browserHistory,
}) {
  function scrollTo(elementId) {
    setMobMenuOpened(false);

    window.scrollTo({
      top: document.getElementById(elementId).offsetTop,
      behavior: "smooth",
    });
  }

  return (
    <Transition
      items={menuOpened}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
      config={{ tension: 250 }}
    >
      {(menuOpened) =>
        menuOpened &&
        ((props) => (
          <div
            style={props}
            className={classNames("menu menu-f-scr-2", className)}
          >
            <div className="menu-f-scr-2__header">
              <div className="menu-f-scr-2__header-content">
                <div className="menu-f-scr-2__social">
                  <MenuLink
                    href="https://instagram.com/indigoprintsamara"
                    target="_blank"
                  >
                    <img className="menu-f-scr-2__social-icon" src={instIcon} />
                  </MenuLink>

                  <MenuLink
                    href="https://vk.com/indigo_v_samare"
                    target="_blank"
                  >
                    <img className="menu-f-scr-2__social-icon" src={vkIcon} />
                  </MenuLink>
                </div>

                <MenuButton
                  className="menu-f-scr-2__button-close"
                  onClick={() => setMobMenuOpened(false)}
                >
                  <img src={closeIcon} alt="" />
                </MenuButton>
              </div>
            </div>

            <div className="menu-f-scr-2__content">
              <div className="block__bg-wrapper features__bg-wrapper menu-f-scr-2__bg-wrapper">
                <img className="block__bg" src={bg} alt="background" />
              </div>

              <div className="menu-f-scr-2__logo">
                <img src={logo} />
              </div>

              <div className="menu__links menu-f-scr-2__links">
                <MenuButton
                  onClick={() => {
                    setMobMenuOpened(false);
                    if (browserHistory.location.pathname === "/") {
                      scrollTo("about-us");
                    }
                  }}
                  to={(location) => {
                    return {
                      ...location,
                      pathname: "/",
                      state: { offsetTop: "about-us", fromMain: false },
                    };
                  }}
                >
                  О нас
                </MenuButton>

                <MenuButton
                  onClick={() => {
                    setMobMenuOpened(false);
                    if (browserHistory.location.pathname === "/") {
                      scrollTo("products");
                    }
                  }}
                  to={(location) => {
                    return {
                      ...location,
                      pathname: "/",
                      state: { offsetTop: "products", fromMain: false },
                    };
                  }}
                >
                  Продукция
                </MenuButton>

                <MenuButton
                  onClick={() => {
                    setMobMenuOpened(false);
                    if (browserHistory.location.pathname === "/") {
                      scrollTo("partners");
                    }
                  }}
                  to={(location) => {
                    return {
                      ...location,
                      pathname: "/",
                      state: { offsetTop: "partners", fromMain: false },
                    };
                  }}
                >
                  Наши партнеры
                </MenuButton>

                <MenuButton
                  onClick={() => {
                    setMobMenuOpened(false);
                    if (browserHistory.location.pathname === "/") {
                      scrollTo("contact-form");
                    }
                  }}
                  to={(location) => {
                    return {
                      ...location,
                      pathname: "/",
                      state: { offsetTop: "contact-form", fromMain: false },
                    };
                  }}
                >
                  Сделать заказ
                </MenuButton>

                <MenuButton
                  onClick={() => {
                    setMobMenuOpened(false);
                    if (browserHistory.location.pathname === "/") {
                      scrollTo("contacts");
                    }
                  }}
                  to={(location) => {
                    return {
                      ...location,
                      pathname: "/",
                      state: { offsetTop: "contacts", fromMain: false },
                    };
                  }}
                >
                  Контакты
                </MenuButton>
              </div>
            </div>
          </div>
        ))
      }
    </Transition>
  );
}
