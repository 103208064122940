/*
Props
  alignment: intro-8_align-start для выравнивания контента по левой части экрана (flex-start)
*/

import React from 'react';
import classNames from 'classnames';

import Button from '../../../components/Button/Button';
import bg from '../../../files/background.png';
import './intro-8.css';

export default function Intro_8({className, alignment, ...otherProps }) {
  function scrollTo(elementId) {
    window.scrollTo({
      top: document.getElementById(elementId).offsetTop,
      behavior: "smooth",
    });
  }

  return (
    <div className={classNames("block intro-8", className)} {...otherProps}>
      <div className="block__bg-wrapper intro-8__bg-wrapper">
        <img className="block__bg" src={bg} alt="background" />
      </div>
      
      <div className="block__content_column">
        <div className={classNames("intro-8__content", alignment)}>
          <h1 className={classNames("intro-8__heading", alignment)}>
            Рекламно-производственная компания «Индиго» 
          </h1>

          <p className={classNames("text_big-body intro-8__text-big-body", alignment)}>
            Профессиональная печать на ткани.<br />
            Флажная и текстильная продукция.<br />
            Деловые сувениры и подарки.
          </p>

          <div className="intro-8__buttons-block">
            <Button className="intro-8__button-first" onClick={() => scrollTo('contact-form')}>Сделать заказ</Button>
          </div>
        </div>
      </div>
    </div>
  )
}