import React, { useState, useEffect } from "react";
import axios from "axios";
import classNames from "classnames";
import "./InstagramWidget.css";

const nFormat = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "m";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "k";
  }
  return num;
};

function Header(props) {
  let userData = props.userData;
  let color = props.color ? props.color : "#3897f0";

  return (
    <div className="widget__header" style={{ backgroundColor: color }}>
      <img src={userData.profile_pic_url} className="w-header__avatar" />
      <span className="w-header__username">{userData.full_name}</span>
      {/* <span className="w-header__logo">
        <svg viewBox="0 0 24 24" width="24" height="24">
          <path d="M17.1,1H6.9C3.7,1,1,3.7,1,6.9v10.1C1,20.3,3.7,23,6.9,23h10.1c3.3,0,5.9-2.7,5.9-5.9V6.9C23,3.7,20.3,1,17.1,1zM21.5,17.1c0,2.4-2,4.4-4.4,4.4H6.9c-2.4,0-4.4-2-4.4-4.4V6.9c0-2.4,2-4.4,4.4-4.4h10.3c2.4,0,4.4,2,4.4,4.4V17.1z"></path>
          <path d="M16.9,11.2c-0.2-1.1-0.6-2-1.4-2.8c-0.8-0.8-1.7-1.2-2.8-1.4c-0.5-0.1-1-0.1-1.4,0C10,7.3,8.9,8,8.1,9S7,11.4,7.2,12.7C7.4,14,8,15.1,9.1,15.9c0.9,0.6,1.9,1,2.9,1c0.2,0,0.5,0,0.7-0.1c1.3-0.2,2.5-0.9,3.2-1.9C16.8,13.8,17.1,12.5,16.9,11.2zM12.6,15.4c-0.9,0.1-1.8-0.1-2.6-0.6c-0.7-0.6-1.2-1.4-1.4-2.3c-0.1-0.9,0.1-1.8,0.6-2.6c0.6-0.7,1.4-1.2,2.3-1.4c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0c1.5,0.2,2.7,1.4,2.9,2.9C15.8,13.3,14.5,15.1,12.6,15.4z"></path>
          <path d="M18.4,5.6c-0.2-0.2-0.4-0.3-0.6-0.3s-0.5,0.1-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.6s0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3s0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6C18.7,5.9,18.6,5.7,18.4,5.6z"></path>
        </svg>
      </span> */}
    </div>
  );
}

function StatsPanel(props) {
  let userData = props.userData;
  return (
    <div className="widget__stats">
      <div className="stats">
        <p className="stats__number">
          {nFormat(userData.edge_owner_to_timeline_media.count)}
        </p>
        <p className="stats__title">posts</p>
      </div>

      <div className="stats">
        <p className="stats__number">
          {nFormat(userData.edge_followed_by.count)}
        </p>
        <p className="stats__title">followers</p>
      </div>

      <div className="stats">
        <p className="stats__number">{nFormat(userData.edge_follow.count)}</p>
        <p className="stats__title">following</p>
      </div>
    </div>
  );
}

function Gallery(props) {
  let userData = props.userData;
  let edges = (() => {
    let edgesVisible = [];
    for (let i = 0; i < 9; i++) {
      edgesVisible.push(userData.edge_owner_to_timeline_media.edges[i]);
    }
    return edgesVisible;
  })();
  let photos = edges.map(({ node }) => {
    let url = `https://www.instagram.com/p/${node.shortcode}/`;
    let thumbnailUrl = node.thumbnail_src;
    let displayUrl = node.display_url;
    let caption = node.edge_media_to_caption.edges[0];
    let likesCount = node.edge_liked_by.count;
    let commentCount = node.edge_media_to_comment.count;

    let photo = {
      url,
      thumbnailUrl,
      displayUrl,
      caption: caption ? caption.node.text : " ",
      likesCount:
        likesCount > 0 ? <span>&#x2764; {nFormat(likesCount)}</span> : " ",
      commentCount:
        commentCount > 0 ? <span>&#x1F4AC; {nFormat(commentCount)}</span> : " ",
    };

    return (
      <a
        href={photo.url}
        target="_blank"
        rel="noopener noreferrer"
        className="photo__link"
        key={node.id}
      >
        <img
          src={photo.thumbnailUrl}
          alt={photo.caption}
          className="photo__image"
        />
        <div className="photo__caption">
          <p>
            {/* {photo.caption}
            <br /> */}
            {photo.likesCount} {photo.commentCount}
            <br />
          </p>
        </div>
      </a>
    );
  });

  return <div className="widget__gallery">{photos}</div>;
}

function Footer(props) {
  let userData = props.userData;
  let color = props.color;

  return (
    <div className="widget__footer">
      <a
        href={`https://instagram.com/${userData.username}`}
        className="footer__button"
        target="_blank"
        style={{ backgroundColor: color }}
      >
        Открыть в Instagram
      </a>
    </div>
  );
}

export default function InstagramWidget({
  widgetSettings,
  className,
  userData,
  ...otherProps
}) {
  let widgetStyle = !widgetSettings.showHeader
    ? {
        border: "none",
        boxShadow: "none",
      }
    : {};

  return (
    <>
      <div
        className={classNames("instagram__widget", className)}
        style={widgetStyle}
        {...otherProps}
      >
        {userData && (
          <>
            {widgetSettings.showHeader && (
              <Header userData={userData} color="#036ED1" />
            )}
            <StatsPanel userData={userData} />
            <Gallery userData={userData} />
            <Footer userData={userData} color="#036ED1" />
          </>
        )}
      </div>
    </>
  );
}
