import React from "react";
import "./features-3.css";

import classNames from "classnames";
import { Link } from "react-router-dom";

import Carousel from "../../../components/Carousel/Carousel";
import Card, {
  CardIcon,
  CardTextWrapper,
  CardHeader,
  CardText,
} from "../../../components/Card/Card";

// import card1Icon from './files/card-1-icon.svg';
// import card2Icon from "./files/card-2-icon.svg";
// import card3Icon from "./files/card-3-icon.svg";
// import card4Icon from "./files/card-4-icon.svg";
// import card5Icon from "./files/card-5-icon.svg";
// import card6Icon from "./files/card-6-icon.svg";
// import card7Icon from "./files/card-7-icon.svg";
// import card8Icon from "./files/card-8-icon.svg";
// import card9Icon from "./files/card-9-icon.svg";
// import card10Icon from "./files/card-10-icon.svg";

import card1Icon from "./files/card-1-icon.png";
import card2Icon from "./files/card-2-icon.png";
import card3Icon from "./files/card-3-icon.png";
import card4Icon from "./files/card-4-icon.png";
import card5Icon from "./files/card-5-icon.png";
import card6Icon from "./files/card-6-icon.png";
import card7Icon from "./files/card-7-icon.png";
import card8Icon from "./files/card-8-icon.png";
import card9Icon from "./files/card-9-icon.png";
import card10Icon from "./files/card-10-icon.png";
import card11Icon from './files/card-11-icon.png';
import card12Icon from './files/card-12-icon.png';
// import bg from './files/features-3__bg.png';
import bg from "../../../files/background.png";

export default function features_3({
  className,
  browserHistory,
  ...otherProps
}) {
  return (
    <div className={classNames("block features-3", className)} {...otherProps}>
      <div className="block__bg-wrapper features__bg-wrapper">
        <img className="block__bg" src={bg} alt="background" />
      </div>

      <div className="block__content_column features-3__content">
        <h2 className="features-3__header">Продукция</h2>

        <Carousel>
          <Link
            to={(location) => {
              return {
                ...location,
                pathname: "/flags",
                state: { fromMain: true, offsetTop: window.pageYOffset },
              };
            }}
          >
            <Card>
              <CardIcon
                src={card1Icon}
                style={{ width: "93px", height: "106px" }}
              />

              <CardTextWrapper>
                <CardHeader className="features-3__card-header">
                  Флаги и вымпела
                </CardHeader>
              </CardTextWrapper>
            </Card>
          </Link>

          <Link
            to={(location) => {
              return {
                ...location,
                pathname: "/clothes",
                state: { fromMain: true, offsetTop: window.pageYOffset },
              };
            }}
          >
            <Card>
              <CardIcon
                src={card2Icon}
                style={{ width: "126px", height: "91px" }}
              />

              <CardTextWrapper>
                <CardHeader className="features-3__card-header">
                  Одежда
                </CardHeader>
              </CardTextWrapper>
            </Card>
          </Link>

          <Link
            to={(location) => {
              return {
                ...location,
                pathname: "/accessories",
                state: { fromMain: true, offsetTop: window.pageYOffset },
              };
            }}
          >
            <Card>
              <CardIcon
                src={card3Icon}
                style={{ width: "70px", height: "122px" }}
              />

              <CardTextWrapper>
                <CardHeader className="features-3__card-header">
                  Аксессуары
                </CardHeader>
              </CardTextWrapper>
            </Card>
          </Link>

          <Link
            to={(location) => {
              return {
                ...location,
                pathname: "/tapes",
                state: { fromMain: true, offsetTop: window.pageYOffset },
              };
            }}
          >
            <Card>
              <CardIcon
                src={card4Icon}
                style={{ width: "102px", height: "102px" }}
              />

              <CardTextWrapper>
                <CardHeader className="features-3__card-header">
                  Ленты
                </CardHeader>
              </CardTextWrapper>
            </Card>
          </Link>

          <Link
            to={(location) => {
              return {
                ...location,
                pathname: "/souvenirs",
                state: { fromMain: true, offsetTop: window.pageYOffset },
              };
            }}
          >
            <Card>
              <CardIcon
                src={card5Icon}
                style={{ width: "77px", height: "111px" }}
              />

              <CardTextWrapper>
                <CardHeader className="features-3__card-header">
                  Сувениры
                </CardHeader>
              </CardTextWrapper>
            </Card>
          </Link>

          <Link
            to={(location) => {
              return {
                ...location,
                pathname: "/aprons",
                state: { fromMain: true, offsetTop: window.pageYOffset },
              };
            }}
          >
            <Card>
              <CardIcon
                src={card6Icon}
                style={{ width: "80px", height: "110px" }}
              />

              <CardTextWrapper>
                <CardHeader className="features-3__card-header">
                  Фартуки и жилеты
                </CardHeader>
              </CardTextWrapper>
            </Card>
          </Link>

          <Link
            to={(location) => {
              return {
                ...location,
                pathname: "/bags",
                state: { fromMain: true, offsetTop: window.pageYOffset },
              };
            }}
          >
            <Card>
              <CardIcon
                src={card7Icon}
                style={{ width: "110px", height: "113px" }}
              />

              <CardTextWrapper>
                <CardHeader className="features-3__card-header">
                  Сумки
                </CardHeader>
              </CardTextWrapper>
            </Card>
          </Link>

          <Link
            to={(location) => {
              return {
                ...location,
                pathname: "/presents",
                state: { fromMain: true, offsetTop: window.pageYOffset },
              };
            }}
          >
            <Card>
              <CardIcon
                src={card8Icon}
                style={{ width: "134px", height: "106px" }}
              />

              <CardTextWrapper>
                <CardHeader className="features-3__card-header">
                  Подарки
                </CardHeader>
              </CardTextWrapper>
            </Card>
          </Link>

          <Link
            to={(location) => {
              return {
                ...location,
                pathname: "/polygraphy",
                state: { fromMain: true, offsetTop: window.pageYOffset },
              };
            }}
          >
            <Card>
              <CardIcon
                src={card9Icon}
                style={{ width: "59px", height: "123px" }}
              />

              <CardTextWrapper>
                <CardHeader className="features-3__card-header">
                  Полиграфия
                </CardHeader>
              </CardTextWrapper>
            </Card>
          </Link>

          <Link
            to={(location) => {
              return {
                ...location,
                pathname: "/interior",
                state: { fromMain: true, offsetTop: window.pageYOffset },
              };
            }}
          >
            <Card>
              <CardIcon
                src={card10Icon}
                style={{ width: "107px", height: "107px" }}
              />

              <CardTextWrapper>
                <CardHeader className="features-3__card-header">
                  Интерьерная печать
                </CardHeader>
              </CardTextWrapper>
            </Card>
          </Link>

          <Link
            to={(location) => {
              return {
                ...location,
                pathname: "/dtf",
                state: { fromMain: true, offsetTop: window.pageYOffset },
              };
            }}
          >
            <Card>
              <CardIcon
                src={card11Icon}
                style={{ width: "107px", height: "107px" }}
              />

              <CardTextWrapper>
                <CardHeader className="features-3__card-header">
                  DTF
                </CardHeader>
              </CardTextWrapper>
            </Card>
          </Link>

          <Link
            to={(location) => {
              return {
                ...location,
                pathname: "/needle",
                state: { fromMain: true, offsetTop: window.pageYOffset },
              };
            }}
          >
            <Card>
              <CardIcon
                src={card12Icon}
                style={{ width: "107px", height: "107px" }}
              />

              <CardTextWrapper>
                <CardHeader className="features-3__card-header">
                  Вышивка
                </CardHeader>
              </CardTextWrapper>
            </Card>
          </Link>
        </Carousel>
      </div>
    </div>
  );
}
