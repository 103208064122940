import React, { useEffect } from 'react';
import './Map.css';

import placeMark from './files/placemark.svg';

export default function Map({ initMap }) {
  useEffect(() => {
    const map = document.createElement("script");
    map.src = "https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=10217188-c891-4239-a57e-37b0408a8507";
    map.type = "text/javascript";
    map.onload = startMap;
    document.head.appendChild(map);
    
    function startMap() {
      window.ymaps.ready(initPreset);
    }
    
    function initPreset() {
      let mapOptions = {
        map: {
          controls: [],
          iconColor: 'green'
        },
        placeMark: {
          iconLayout: 'default#image',
          iconImageHref: placeMark,
          iconImageSize: [32, 48]
          
        }
      }

      initMap(mapOptions);
    }
  }, [])  

  return (
    <div id="map" className="map" />
  )
}