import React, { useState } from "react";
import "./contact-form-1.css";

import classNames from "classnames";
import { useTransition, animated } from "react-spring";

import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import InstagramWidget from "../../../components/InstagramWidget/InstagramWidget";
import bg from "../../../files/background.png";

const axios = require("axios");

export default function ContactForm1({ className, instUserData, ...otherProps }) {
  const [name, setName] = useState({ value: "", error: true });
  const [company, setCompany] = useState({ value: "", error: true });
  const [phone, setPhone] = useState({ value: "", error: true });
  const [message, setMessage] = useState({ value: "", error: false });
  const [email, setEmail] = useState({ value: "", error: false });
  const [displayError, setDisplayError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  function dropInputs() {
    setName({ value: "", error: true });
    setCompany({ value: "", error: true });
    setPhone({ value: "", error: true });
    setMessage({ value: "", error: true });
    setEmail({ value: "", error: true });
    setDisplayError(false);
  }

  function handleInput(e, error) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const targetName = target.name;

    const setValue = {
      name: (value) =>
        setName({
          value: value,
          error: error.error,
        }),
      company: (value) =>
        setCompany({
          value: value,
          error: error.error,
        }),
      phone: (value) =>
        setPhone({
          value: value,
          error: error.error,
        }),
      email: (value) =>
        setEmail({
          value: value,
          error: error.error,
        }),
      message: (value) =>
        setMessage({
          value: value,
          error: error.error,
        }),
    };
    setValue[targetName](value);
  }

  function order(e) {
    e.preventDefault();

    if (name.error || email.error || phone.error) {
      setDisplayError(true);
      return;
    }

    let orderData = {
      name: name.value,
      company: company.value,
      phone: phone.value,
      email: email.value,
      message: message.value,
    };

    axios
      .post("order", orderData)
      .then((response) => {
        setSuccess(true);
        setError(false);
        dropInputs();
      })
      .catch((error) => {
        setSuccess(false);
        setError(true);
        console.log(error);
      });
  }

  const errorAnimation = useTransition(error, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const successAnimation = useTransition(success, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <div className={classNames("block c-form-1", className)} {...otherProps}>
      <div className="block__bg-wrapper features__bg-wrapper">
        <img className="block__bg" src={bg} alt="background" />
      </div>

      <div className="block__content_column c-form-1__content">
        <h2 className="c-form-1__header">Сделать заказ</h2>

        <div className="c-form-1__form-inst-wrapper">
          <form className="c-form-1__form" onSubmit={(e) => order(e)}>
            <Input
              value={name.value}
              className="c-form-1__input"
              className_wrapper="c-form-1__input-wrapper"
              className_errorText="c-form-1__error-text"
              name="name"
              placeholder="Имя"
              handleInput={handleInput}
              minLength="3"
              maxLength="35"
              displayErrorProp={displayError}
              errorText="3-35 символов, только буквы и '-'"
            />

            <Input
              value={phone.value}
              className="c-form-1__input"
              className_wrapper="c-form-1__input-wrapper"
              className_errorText="c-form-1__error-text"
              type="tel"
              name="phone"
              placeholder="Телефон"
              handleInput={handleInput}
              displayErrorProp={displayError}
            />

            <Input
              value={email.value}
              className="c-form-1__input"
              className_wrapper="c-form-1__input-wrapper"
              className_errorText="c-form-1__error-text"
              type="email"
              name="email"
              placeholder="Почта"
              handleInput={handleInput}
              displayErrorProp={displayError}
            />

            <Input
              value={company.value}
              className="c-form-1__input"
              className_wrapper="c-form-1__input-wrapper"
              className_errorText="c-form-1__error-text"
              name="company"
              placeholder="Компания"
              handleInput={handleInput}
              displayErrorProp={displayError}
            />

            <Input
              value={message.value}
              className="c-form-1__input c-form-1__input_textarea"
              className_wrapper="c-form-1__input-wrapper"
              className_errorText="c-form-1__error-text"
              type="textarea"
              name="message"
              placeholder="Сообщение"
              handleInput={handleInput}
              displayErrorProp={displayError}
            />

            <div className="c-form-1__bottom">
              <Button className="c-form-1__button">Отправить</Button>

              {/* <Transition
                items={error}
              >

              </Transition> */}

              {successAnimation.map(
                ({ item, key, props }) =>
                  item && (
                    <animated.div
                      key={key}
                      style={props}
                      className="c-form-1__result-wrapper"
                    >
                      <p className="c-form-1__success">
                        Спасибо за заявку! Мы свяжемся с вами в ближайшее время!
                      </p>
                    </animated.div>
                  )
              )}

              {errorAnimation.map(
                ({ item, key, props }) =>
                  item && (
                    <animated.div
                      key={key}
                      style={props}
                      className="c-form-1__result-wrapper"
                    >
                      <p className="c-form-1__error">
                        Произошла ошибка... Попробуйте еще раз или свяжитесь с
                        нами другим способом. Извините за неудобства!
                      </p>
                    </animated.div>
                  )
              )}
            </div>
          </form>

          <div className="c-form-1__inst-block">
            <InstagramWidget
              widgetSettings={{
                color: "#3897f0",
                showHeader: true,
                width: "100%",
              }}
              userData={instUserData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
