import React from 'react';
import './Card.css';

import classNames from 'classnames';

export function CardIcon({className, src, ...otherProps}) {
  return (
    <div className={classNames('card__icon-wrapper')}>
      <img className={classNames('card__icon', className)} src={src} alt="icon" {...otherProps} />
    </div>
  )
}

export function CardTextWrapper({children, className}) {
  return (
    <div className={classNames('card__text-wrapper', className)}>
      {children}
    </div>
  )
}

export function CardHeader({children, className}) {
  return (
    <h4 className={classNames('card__header', className)}>
      {children}
    </h4>
  )
}

export function CardText({children, className}) {
  return (
    <p className={classNames('text_body card__text', className)}>
      {children}
    </p>
  )
}

export default function Card({children, className, ...otherProps}) {
  return (
    <a className={classNames('card', className)} {...otherProps}>
      {children}
    </a>
  )
}
