import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { YMInitializer } from "react-yandex-metrika";
import axios from "axios";
import smoothscroll from "smoothscroll-polyfill";
import "./App.css";
import "./variables.css";
import "./fonts/fonts.css";

import MainPage from "./pages/main/MainPage";
// import ProductPage from './blocks/product-page/1/p-page-1';
// import Header4 from './blocks/header/4/header-4';
// import Footer1 from './blocks/footer/1/footer-1';

// import Intro1 from './blocks/intro/1/intro-1';
// import Intro2 from "./blocks/intro/2/intro-2";
// import Intro8 from "./blocks/intro/8/intro-8";
// import AboutUs from "./blocks/about-us/about-us";
// import ContactsMap1 from "./blocks/contacts/map/1/contacts-map-1";
// import Features3 from "./blocks/features/3/features-3";
// import Features4 from "./blocks/features/4/features-4";
// import Portfolio1 from "./blocks/portfolio/1/portfolio-1";
import Portfolio6 from "./blocks/portfolio/6/portfolio-6";
import Reviews1 from "./blocks/reviews/1/reviews-1";
import Header4 from "./blocks/header/4/header-4";
import Header6 from "./blocks/header/6/header-6";
import ContactForm1 from "./blocks/contact-form/1/contact-form-1";
import PriceTable2 from "./blocks/price/table/2/price-table-2";
import PriceTable4 from "./blocks/price/table/4/price-table-4";
import Footer1 from "./blocks/footer/1/footer-1";
import ProductPage from "./pages/product/ProductPage";

// import Test from './blocks/test/test';

import flagsPhoto1 from "./files/products/flags/1.jpg";
import flagsPhoto2 from "./files/products/flags/2.jpg";
import flagsPhoto3 from "./files/products/flags/3.jpg";
import flagsPhoto4 from "./files/products/flags/4.jpg";
import flagsPhoto5 from "./files/products/flags/5.jpg";
import flagsPhoto6 from "./files/products/flags/6.jpg";
import flagsPhoto7 from "./files/products/flags/7.jpg";
import flagsPhoto8 from "./files/products/flags/8.jpg";
import flagsPhoto9 from "./files/products/flags/9.jpg";
import flagsPhoto10 from "./files/products/flags/10.jpg";
import flagsPhoto11 from "./files/products/flags/11.jpg";
import flagsPhoto12 from "./files/products/flags/12.jpg";
import flagsPhoto13 from "./files/products/flags/13.jpg";
import flagsPhoto14 from "./files/products/flags/14.jpg";
import flagsPhoto15 from "./files/products/flags/15.jpg";
import flagsPhoto16 from "./files/products/flags/16.jpg";
import flagsPhoto17 from "./files/products/flags/17.jpg";
import flagsPhoto18 from "./files/products/flags/18.jpg";
import flagsPhoto19 from "./files/products/flags/19.jpg";
import flagsPhoto20 from "./files/products/flags/20.jpg";
import flagsPhoto21 from "./files/products/flags/21.jpg";
import flagsPhoto22 from "./files/products/flags/22.jpg";
import flagsPhoto23 from "./files/products/flags/23.jpg";
import flagsPhoto24 from "./files/products/flags/24.jpg";
import flagsPhoto25 from "./files/products/flags/25.jpg";

import clothesPhoto1 from "./files/products/clothes/1.jpg";
import clothesPhoto2 from "./files/products/clothes/2.jpg";
import clothesPhoto3 from "./files/products/clothes/3.jpg";
import clothesPhoto4 from "./files/products/clothes/4.jpg";
import clothesPhoto5 from "./files/products/clothes/5.jpg";
import clothesPhoto6 from "./files/products/clothes/6.jpg";
import clothesPhoto7 from "./files/products/clothes/7.jpg";
import clothesPhoto8 from "./files/products/clothes/8.jpg";
import clothesPhoto9 from "./files/products/clothes/9.jpg";
import clothesPhoto10 from "./files/products/clothes/10.jpg";
import clothesPhoto11 from "./files/products/clothes/11.jpg";
import clothesPhoto12 from "./files/products/clothes/12.jpg";
import clothesPhoto13 from "./files/products/clothes/13.jpg";
import clothesPhoto14 from "./files/products/clothes/14.jpg";
import clothesPhoto15 from "./files/products/clothes/15.jpg";
import clothesPhoto16 from "./files/products/clothes/16.jpg";
import clothesPhoto17 from "./files/products/clothes/17.jpg";
import clothesPhoto18 from "./files/products/clothes/18.jpg";
import clothesPhoto19 from "./files/products/clothes/19.jpg";
import clothesPhoto20 from "./files/products/clothes/20.jpg";
import clothesPhoto21 from "./files/products/clothes/21.jpg";
import clothesPhoto22 from "./files/products/clothes/22.png";
import clothesPhoto23 from "./files/products/clothes/23.png";
import clothesPhoto24 from "./files/products/clothes/24.png";
import clothesPhoto25 from "./files/products/clothes/25.jpg";
import clothesPhoto26 from "./files/products/clothes/26.jpg";
import clothesPhoto27 from "./files/products/clothes/27.jpg";
import clothesPhoto28 from "./files/products/clothes/28.jpg";
import clothesPhoto29 from "./files/products/clothes/29.jpg";
import clothesPhoto30 from "./files/products/clothes/30.jpg";
import clothesPhoto31 from "./files/products/clothes/31.jpg";
import clothesPhoto32 from "./files/products/clothes/32.jpg";
import clothesPhoto33 from "./files/products/clothes/33.jpg";
import clothesPhoto34 from "./files/products/clothes/34.jpg";
import clothesPhoto35 from "./files/products/clothes/35.jpg";
import clothesPhoto36 from "./files/products/clothes/36.jpg";
import clothesPhoto37 from "./files/products/clothes/37.jpg";
import clothesPhoto38 from "./files/products/clothes/38.jpg";
import clothesPhoto39 from "./files/products/clothes/39.jpg";
import clothesPhoto40 from "./files/products/clothes/40.jpg";
import clothesPhoto41 from "./files/products/clothes/41.jpg";
import clothesPhoto42 from "./files/products/clothes/42.jpg";
import clothesPhoto43 from "./files/products/clothes/43.jpg";
import clothesPhoto44 from "./files/products/clothes/44.jpg";
import clothesPhoto45 from "./files/products/clothes/45.jpg";

import accessoriesPhoto1 from "./files/products/accessories/1.jpg";
import accessoriesPhoto2 from "./files/products/accessories/2.jpg";
import accessoriesPhoto3 from "./files/products/accessories/3.jpg";
import accessoriesPhoto4 from "./files/products/accessories/4.jpg";
import accessoriesPhoto5 from "./files/products/accessories/5.jpg";
import accessoriesPhoto6 from "./files/products/accessories/6.jpg";
import accessoriesPhoto7 from "./files/products/accessories/7.jpg";
import accessoriesPhoto8 from "./files/products/accessories/8.jpg";
import accessoriesPhoto9 from "./files/products/accessories/9.jpg";
import accessoriesPhoto10 from "./files/products/accessories/10.jpg";
import accessoriesPhoto11 from "./files/products/accessories/11.jpg";
import accessoriesPhoto12 from "./files/products/accessories/12.jpg";
import accessoriesPhoto13 from "./files/products/accessories/13.jpg";
import accessoriesPhoto14 from "./files/products/accessories/14.jpg";
import accessoriesPhoto15 from "./files/products/accessories/15.jpg";
import accessoriesPhoto16 from "./files/products/accessories/16.jpg";
import accessoriesPhoto17 from "./files/products/accessories/17.jpg";
import accessoriesPhoto18 from "./files/products/accessories/18.jpg";
import accessoriesPhoto19 from "./files/products/accessories/19.jpg";
import accessoriesPhoto20 from "./files/products/accessories/20.jpg";
import accessoriesPhoto21 from "./files/products/accessories/21.jpg";
import accessoriesPhoto22 from "./files/products/accessories/22.jpg";
import accessoriesPhoto23 from "./files/products/accessories/23.jpg";

import tapesPhoto1 from "./files/products/tapes/1.jpg";
import tapesPhoto2 from "./files/products/tapes/2.jpg";
import tapesPhoto3 from "./files/products/tapes/3.jpg";
import tapesPhoto4 from "./files/products/tapes/4.jpg";
import tapesPhoto5 from "./files/products/tapes/5.jpg";
import tapesPhoto6 from "./files/products/tapes/6.jpg";
import tapesPhoto7 from "./files/products/tapes/7.jpg";
import tapesPhoto8 from "./files/products/tapes/8.jpg";
import tapesPhoto9 from "./files/products/tapes/9.jpg";
import tapesPhoto10 from "./files/products/tapes/10.jpg";
import tapesPhoto11 from "./files/products/tapes/11.jpg";
import tapesPhoto12 from "./files/products/tapes/12.jpg";
import tapesPhoto13 from "./files/products/tapes/13.jpg";
import tapesPhoto14 from "./files/products/tapes/14.jpg";
import tapesPhoto15 from "./files/products/tapes/15.jpg";
import tapesPhoto16 from "./files/products/tapes/16.jpg";
import tapesPhoto17 from "./files/products/tapes/17.jpg";
import tapesPhoto18 from "./files/products/tapes/18.jpg";
import tapesPhoto19 from "./files/products/tapes/19.png";
import tapesPhoto20 from "./files/products/tapes/20.png";
import tapesPhoto21 from "./files/products/tapes/21.jpg";
import tapesPhoto22 from "./files/products/tapes/22.jpg";

import souvenirsPhoto1 from "./files/products/souvenirs/1.jpg";
import souvenirsPhoto2 from "./files/products/souvenirs/2.jpg";
import souvenirsPhoto3 from "./files/products/souvenirs/3.jpg";
import souvenirsPhoto4 from "./files/products/souvenirs/4.jpg";
import souvenirsPhoto5 from "./files/products/souvenirs/5.jpg";
import souvenirsPhoto6 from "./files/products/souvenirs/6.jpg";
import souvenirsPhoto7 from "./files/products/souvenirs/7.jpg";
import souvenirsPhoto8 from "./files/products/souvenirs/8.jpg";
import souvenirsPhoto9 from "./files/products/souvenirs/9.jpg";
import souvenirsPhoto10 from "./files/products/souvenirs/10.jpg";
import souvenirsPhoto11 from "./files/products/souvenirs/11.jpg";
import souvenirsPhoto12 from "./files/products/souvenirs/12.jpg";
import souvenirsPhoto13 from "./files/products/souvenirs/13.jpg";
import souvenirsPhoto14 from "./files/products/souvenirs/14.jpg";
import souvenirsPhoto15 from "./files/products/souvenirs/15.jpg";
import souvenirsPhoto16 from "./files/products/souvenirs/16.jpg";
import souvenirsPhoto17 from "./files/products/souvenirs/17.jpg";
import souvenirsPhoto18 from "./files/products/souvenirs/18.jpg";
import souvenirsPhoto19 from "./files/products/souvenirs/19.jpg";
import souvenirsPhoto20 from "./files/products/souvenirs/20.jpg";
import souvenirsPhoto21 from "./files/products/souvenirs/21.jpg";
import souvenirsPhoto22 from "./files/products/souvenirs/22.jpg";
import souvenirsPhoto23 from "./files/products/souvenirs/23.jpg";
import souvenirsPhoto24 from "./files/products/souvenirs/24.jpg";
import souvenirsPhoto25 from "./files/products/souvenirs/25.jpg";
import souvenirsPhoto26 from "./files/products/souvenirs/26.jpg";
import souvenirsPhoto27 from "./files/products/souvenirs/27.jpg";
import souvenirsPhoto28 from "./files/products/souvenirs/28.jpg";
import souvenirsPhoto29 from "./files/products/souvenirs/29.jpg";
import souvenirsPhoto30 from "./files/products/souvenirs/30.jpg";
import souvenirsPhoto31 from "./files/products/souvenirs/31.jpg";
import souvenirsPhoto32 from "./files/products/souvenirs/32.jpg";
import souvenirsPhoto33 from "./files/products/souvenirs/33.jpg";
import souvenirsPhoto34 from "./files/products/souvenirs/34.jpg";
import souvenirsPhoto35 from "./files/products/souvenirs/35.jpg";
import souvenirsPhoto36 from "./files/products/souvenirs/36.jpg";
import souvenirsPhoto37 from "./files/products/souvenirs/37.jpg";
import souvenirsPhoto38 from "./files/products/souvenirs/38.jpg";
import souvenirsPhoto39 from "./files/products/souvenirs/39.jpg";
import souvenirsPhoto40 from "./files/products/souvenirs/40.jpg";
import souvenirsPhoto41 from "./files/products/souvenirs/41.jpg";
import souvenirsPhoto42 from "./files/products/souvenirs/42.jpg";
import souvenirsPhoto43 from "./files/products/souvenirs/43.jpg";
import souvenirsPhoto44 from "./files/products/souvenirs/44.jpg";
import souvenirsPhoto45 from "./files/products/souvenirs/45.jpg";
import souvenirsPhoto46 from "./files/products/souvenirs/46.jpg";
import souvenirsPhoto47 from "./files/products/souvenirs/47.jpg";
import souvenirsPhoto48 from "./files/products/souvenirs/48.jpg";
import souvenirsPhoto49 from "./files/products/souvenirs/49.jpg";

import apronsPhoto1 from "./files/products/aprons/1.jpg";
import apronsPhoto2 from "./files/products/aprons/2.jpg";
import apronsPhoto3 from "./files/products/aprons/3.jpg";
import apronsPhoto4 from "./files/products/aprons/4.jpg";
import apronsPhoto5 from "./files/products/aprons/5.jpg";
import apronsPhoto6 from "./files/products/aprons/6.jpg";
import apronsPhoto7 from "./files/products/aprons/7.jpg";
import apronsPhoto8 from "./files/products/aprons/8.jpg";
import apronsPhoto9 from "./files/products/aprons/9.jpg";
import apronsPhoto10 from "./files/products/aprons/10.jpg";
import apronsPhoto11 from "./files/products/aprons/11.jpg";
import apronsPhoto12 from "./files/products/aprons/12.jpg";
import apronsPhoto13 from "./files/products/aprons/13.jpg";
import apronsPhoto14 from "./files/products/aprons/14.jpg";
import apronsPhoto15 from "./files/products/aprons/15.jpg";
import apronsPhoto16 from "./files/products/aprons/16.jpg";
import apronsPhoto17 from "./files/products/aprons/17.jpg";
import apronsPhoto21 from "./files/products/aprons/21.png";
import apronsPhoto22 from "./files/products/aprons/22.jpg";
import apronsPhoto23 from "./files/products/aprons/23.jpg";
import apronsPhoto24 from "./files/products/aprons/24.jpg";
import apronsPhoto25 from "./files/products/aprons/25.jpg";

import bagsPhoto1 from "./files/products/bags/1.jpg";
import bagsPhoto2 from "./files/products/bags/2.jpg";
import bagsPhoto3 from "./files/products/bags/3.jpg";
import bagsPhoto4 from "./files/products/bags/4.jpg";
import bagsPhoto5 from "./files/products/bags/5.jpg";
import bagsPhoto6 from "./files/products/bags/6.jpg";
import bagsPhoto7 from "./files/products/bags/7.jpg";
import bagsPhoto8 from "./files/products/bags/8.jpg";
import bagsPhoto9 from "./files/products/bags/9.jpg";
import bagsPhoto10 from "./files/products/bags/10.jpg";
import bagsPhoto11 from "./files/products/bags/11.jpg";
import bagsPhoto12 from "./files/products/bags/12.jpg";
import bagsPhoto13 from "./files/products/bags/13.jpg";
import bagsPhoto14 from "./files/products/bags/14.jpg";
import bagsPhoto15 from "./files/products/bags/15.jpg";

import presentsPhoto1 from "./files/products/presents/1.jpg";
import presentsPhoto2 from "./files/products/presents/2.jpg";
import presentsPhoto3 from "./files/products/presents/3.jpg";
import presentsPhoto4 from "./files/products/presents/4.jpg";
import presentsPhoto5 from "./files/products/presents/5.jpg";
import presentsPhoto6 from "./files/products/presents/6.jpg";
import presentsPhoto7 from "./files/products/presents/7.jpg";
import presentsPhoto8 from "./files/products/presents/8.jpg";
import presentsPhoto9 from "./files/products/presents/9.jpg";
import presentsPhoto10 from "./files/products/presents/10.jpg";
import presentsPhoto11 from "./files/products/presents/11.jpg";
import presentsPhoto12 from "./files/products/presents/12.jpg";
import presentsPhoto13 from "./files/products/presents/13.jpg";
import presentsPhoto14 from "./files/products/presents/14.jpg";
import presentsPhoto15 from "./files/products/presents/15.jpg";
import presentsPhoto16 from "./files/products/presents/16.jpg";
import presentsPhoto17 from "./files/products/presents/17.jpg";
import presentsPhoto18 from "./files/products/presents/18.jpg";
import presentsPhoto19 from "./files/products/presents/19.jpg";
import presentsPhoto20 from "./files/products/presents/20.jpg";
import presentsPhoto21 from "./files/products/presents/21.jpg";
import presentsPhoto22 from "./files/products/presents/22.jpg";
import presentsPhoto23 from "./files/products/presents/23.jpg";
import presentsPhoto24 from "./files/products/presents/24.jpg";
import presentsPhoto25 from "./files/products/presents/25.jpg";
import presentsPhoto26 from "./files/products/presents/26.jpg";
import presentsPhoto27 from "./files/products/presents/27.jpg";
import presentsPhoto28 from "./files/products/presents/28.jpg";
import presentsPhoto29 from "./files/products/presents/29.jpg";
import presentsPhoto34 from "./files/products/presents/34.jpg";
import presentsPhoto35 from "./files/products/presents/35.jpg";
import presentsPhoto36 from "./files/products/presents/36.jpg";
import presentsPhoto37 from "./files/products/presents/37.jpg";
import presentsPhoto38 from "./files/products/presents/38.jpg";
import presentsPhoto39 from "./files/products/presents/39.jpg";
import presentsPhoto40 from "./files/products/presents/40.jpg";
import presentsPhoto41 from "./files/products/presents/41.jpg";
import presentsPhoto42 from "./files/products/presents/42.jpg";
import presentsPhoto43 from "./files/products/presents/43.jpg";
import presentsPhoto44 from "./files/products/presents/44.jpg";
import presentsPhoto45 from "./files/products/presents/45.jpg";
import presentsPhoto46 from "./files/products/presents/46.jpg";
import presentsPhoto47 from "./files/products/presents/47.jpg";

import polygraphyPhoto1 from "./files/products/polygraphy/1.jpg";
import polygraphyPhoto2 from "./files/products/polygraphy/2.jpg";
import polygraphyPhoto3 from "./files/products/polygraphy/3.jpg";
import polygraphyPhoto4 from "./files/products/polygraphy/4.jpg";
import polygraphyPhoto5 from "./files/products/polygraphy/5.jpg";
import polygraphyPhoto6 from "./files/products/polygraphy/6.jpg";
import polygraphyPhoto7 from "./files/products/polygraphy/7.jpg";
import polygraphyPhoto8 from "./files/products/polygraphy/8.jpg";
import polygraphyPhoto9 from "./files/products/polygraphy/9.jpg";
import polygraphyPhoto10 from "./files/products/polygraphy/10.jpg";
import polygraphyPhoto11 from "./files/products/polygraphy/11.jpg";
import polygraphyPhoto12 from "./files/products/polygraphy/12.jpg";
import polygraphyPhoto13 from "./files/products/polygraphy/13.jpg";
import polygraphyPhoto14 from "./files/products/polygraphy/14.jpg";
import polygraphyPhoto15 from "./files/products/polygraphy/15.jpg";
import polygraphyPhoto16 from "./files/products/polygraphy/16.jpg";
import polygraphyPhoto17 from "./files/products/polygraphy/17.jpg";
import polygraphyPhoto18 from "./files/products/polygraphy/18.jpg";
import polygraphyPhoto19 from "./files/products/polygraphy/19.jpg";
import polygraphyPhoto20 from "./files/products/polygraphy/20.jpg";
import polygraphyPhoto21 from "./files/products/polygraphy/21.jpg";
import polygraphyPhoto22 from "./files/products/polygraphy/22.jpg";
import polygraphyPhoto23 from "./files/products/polygraphy/23.jpg";
import polygraphyPhoto24 from "./files/products/polygraphy/24.jpg";
import polygraphyPhoto25 from "./files/products/polygraphy/25.jpg";
import polygraphyPhoto26 from "./files/products/polygraphy/26.jpg";
import polygraphyPhoto27 from "./files/products/polygraphy/27.jpg";
import polygraphyPhoto28 from "./files/products/polygraphy/28.jpg";
import polygraphyPhoto29 from "./files/products/polygraphy/29.jpg";
import polygraphyPhoto30 from "./files/products/polygraphy/30.jpg";
import polygraphyPhoto31 from "./files/products/polygraphy/31.jpg";
import polygraphyPhoto32 from "./files/products/polygraphy/32.jpg";
import polygraphyPhoto33 from "./files/products/polygraphy/33.jpg";
import polygraphyPhoto34 from "./files/products/polygraphy/34.jpg";
import polygraphyPhoto35 from "./files/products/polygraphy/35.jpg";
import polygraphyPhoto36 from "./files/products/polygraphy/36.jpg";
import polygraphyPhoto37 from "./files/products/polygraphy/37.jpg";

import interiorPhoto1 from "./files/products/interior/1.jpg";
import interiorPhoto2 from "./files/products/interior/2.jpg";
import interiorPhoto3 from "./files/products/interior/3.jpg";
import interiorPhoto4 from "./files/products/interior/4.jpg";
import interiorPhoto5 from "./files/products/interior/5.jpg";
import interiorPhoto6 from "./files/products/interior/6.jpg";
import interiorPhoto7 from "./files/products/interior/7.jpg";
import interiorPhoto8 from "./files/products/interior/8.jpg";
import interiorPhoto9 from "./files/products/interior/9.jpg";
import interiorPhoto10 from "./files/products/interior/10.jpg";
import interiorPhoto11 from "./files/products/interior/11.jpg";
import interiorPhoto12 from "./files/products/interior/12.jpg";
import interiorPhoto13 from "./files/products/interior/13.jpg";
import interiorPhoto14 from "./files/products/interior/14.jpg";
import interiorPhoto15 from "./files/products/interior/15.jpg";
import interiorPhoto16 from "./files/products/interior/16.jpg";
import interiorPhoto17 from "./files/products/interior/17.jpg";
import interiorPhoto18 from "./files/products/interior/18.jpg";
import interiorPhoto19 from "./files/products/interior/19.jpg";
import interiorPhoto20 from "./files/products/interior/20.jpg";
import interiorPhoto21 from "./files/products/interior/21.jpg";
import interiorPhoto22 from "./files/products/interior/22.jpg";
import interiorPhoto23 from "./files/products/interior/23.jpg";
import interiorPhoto24 from "./files/products/interior/24.jpg";
import interiorPhoto25 from "./files/products/interior/25.jpg";
import interiorPhoto26 from "./files/products/interior/26.jpg";
import interiorPhoto27 from "./files/products/interior/27.jpg";

import dtfPhoto1 from "./files/products/dtf/1.jpg";
import dtfPhoto2 from "./files/products/dtf/2.jpg";
import dtfPhoto3 from "./files/products/dtf/3.jpg";
import dtfPhoto5 from "./files/products/dtf/5.jpg";
import dtfPhoto6 from "./files/products/dtf/6.jpg";
import dtfPhoto7 from "./files/products/dtf/7.jpg";
import dtfPhoto8 from "./files/products/dtf/8.jpg";
import dtfPhoto9 from "./files/products/dtf/9.jpg";
import dtfPhoto10 from "./files/products/dtf/10.jpg";
import dtfPhoto11 from "./files/products/dtf/11.jpg";
import dtfPhoto12 from "./files/products/dtf/12.jpg";
import dtfPhoto13 from "./files/products/dtf/13.jpg";

import needlePhoto1 from "./files/products/needle/1.jpg";
import needlePhoto2 from "./files/products/needle/2.jpg";
import needlePhoto3 from "./files/products/needle/3.jpg";
import needlePhoto4 from "./files/products/needle/4.jpg";
import needlePhoto5 from "./files/products/needle/5.jpg";
import needlePhoto6 from "./files/products/needle/6.jpg";
import needlePhoto7 from "./files/products/needle/7.jpg";
import needlePhoto8 from "./files/products/needle/8.jpg";

import promotionPhoto1 from "./files/promotion/photos/1.jpg";

smoothscroll.polyfill();

function App() {
  const [instUserData, setInstUserData] = useState();

  const productPhotos = {
    promotion: [
      {
        original: promotionPhoto1,
        thumbnail: promotionPhoto1,
        thumbnailClass: "p-page-1__thumbnail",
      },
    ],
    flags: [
      {
        original: flagsPhoto23,
        thumbnail: flagsPhoto23,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto24,
        thumbnail: flagsPhoto24,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto21,
        thumbnail: flagsPhoto21,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto25,
        thumbnail: flagsPhoto25,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto22,
        thumbnail: flagsPhoto22,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto2,
        thumbnail: flagsPhoto2,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto1,
        thumbnail: flagsPhoto1,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto3,
        thumbnail: flagsPhoto3,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto4,
        thumbnail: flagsPhoto4,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto5,
        thumbnail: flagsPhoto5,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto6,
        thumbnail: flagsPhoto6,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto7,
        thumbnail: flagsPhoto7,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto8,
        thumbnail: flagsPhoto8,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto9,
        thumbnail: flagsPhoto9,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto10,
        thumbnail: flagsPhoto10,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto11,
        thumbnail: flagsPhoto11,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto12,
        thumbnail: flagsPhoto12,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto13,
        thumbnail: flagsPhoto13,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto14,
        thumbnail: flagsPhoto14,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto15,
        thumbnail: flagsPhoto15,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto16,
        thumbnail: flagsPhoto16,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto17,
        thumbnail: flagsPhoto17,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto18,
        thumbnail: flagsPhoto18,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto19,
        thumbnail: flagsPhoto19,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: flagsPhoto20,
        thumbnail: flagsPhoto20,
        thumbnailClass: "p-page-1__thumbnail",
      },
    ],
    clothes: [
      {
        original: clothesPhoto39,
        thumbnail: clothesPhoto39,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto40,
        thumbnail: clothesPhoto40,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto41,
        thumbnail: clothesPhoto41,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto42,
        thumbnail: clothesPhoto42,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto43,
        thumbnail: clothesPhoto43,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto44,
        thumbnail: clothesPhoto44,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto45,
        thumbnail: clothesPhoto45,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto37,
        thumbnail: clothesPhoto37,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto38,
        thumbnail: clothesPhoto38,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto35,
        thumbnail: clothesPhoto35,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto36,
        thumbnail: clothesPhoto36,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto34,
        thumbnail: clothesPhoto34,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto30,
        thumbnail: clothesPhoto30,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto31,
        thumbnail: clothesPhoto31,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto32,
        thumbnail: clothesPhoto32,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto33,
        thumbnail: clothesPhoto33,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto25,
        thumbnail: clothesPhoto25,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto26,
        thumbnail: clothesPhoto26,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto27,
        thumbnail: clothesPhoto27,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto28,
        thumbnail: clothesPhoto28,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto29,
        thumbnail: clothesPhoto29,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto22,
        thumbnail: clothesPhoto22,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto23,
        thumbnail: clothesPhoto23,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto24,
        thumbnail: clothesPhoto24,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto17,
        thumbnail: clothesPhoto17,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto18,
        thumbnail: clothesPhoto18,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto19,
        thumbnail: clothesPhoto19,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto20,
        thumbnail: clothesPhoto20,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto21,
        thumbnail: clothesPhoto21,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto1,
        thumbnail: clothesPhoto1,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto2,
        thumbnail: clothesPhoto2,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto3,
        thumbnail: clothesPhoto3,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto4,
        thumbnail: clothesPhoto4,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto5,
        thumbnail: clothesPhoto5,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto6,
        thumbnail: clothesPhoto6,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto7,
        thumbnail: clothesPhoto7,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto8,
        thumbnail: clothesPhoto8,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto9,
        thumbnail: clothesPhoto9,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto10,
        thumbnail: clothesPhoto10,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto11,
        thumbnail: clothesPhoto11,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto12,
        thumbnail: clothesPhoto12,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto13,
        thumbnail: clothesPhoto13,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto14,
        thumbnail: clothesPhoto14,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto15,
        thumbnail: clothesPhoto15,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: clothesPhoto16,
        thumbnail: clothesPhoto16,
        thumbnailClass: "p-page-1__thumbnail",
      },
    ],
    accessories: [
      {
        original: accessoriesPhoto23,
        thumbnail: accessoriesPhoto23,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto17,
        thumbnail: accessoriesPhoto17,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto18,
        thumbnail: accessoriesPhoto18,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto19,
        thumbnail: accessoriesPhoto19,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto20,
        thumbnail: accessoriesPhoto20,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto21,
        thumbnail: accessoriesPhoto21,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto22,
        thumbnail: accessoriesPhoto22,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto15,
        thumbnail: accessoriesPhoto15,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto16,
        thumbnail: accessoriesPhoto16,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto14,
        thumbnail: accessoriesPhoto14,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto10,
        thumbnail: accessoriesPhoto10,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto13,
        thumbnail: accessoriesPhoto13,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto1,
        thumbnail: accessoriesPhoto1,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto2,
        thumbnail: accessoriesPhoto2,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto3,
        thumbnail: accessoriesPhoto3,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto4,
        thumbnail: accessoriesPhoto4,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto5,
        thumbnail: accessoriesPhoto5,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto6,
        thumbnail: accessoriesPhoto6,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto7,
        thumbnail: accessoriesPhoto7,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto8,
        thumbnail: accessoriesPhoto8,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto9,
        thumbnail: accessoriesPhoto9,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto11,
        thumbnail: accessoriesPhoto11,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: accessoriesPhoto12,
        thumbnail: accessoriesPhoto12,
        thumbnailClass: "p-page-1__thumbnail",
      },
    ],
    tapes: [
      {
        original: tapesPhoto21,
        thumbnail: tapesPhoto21,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto22,
        thumbnail: tapesPhoto22,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto15,
        thumbnail: tapesPhoto15,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto16,
        thumbnail: tapesPhoto16,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto17,
        thumbnail: tapesPhoto17,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto18,
        thumbnail: tapesPhoto18,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto19,
        thumbnail: tapesPhoto19,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto20,
        thumbnail: tapesPhoto20,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto14,
        thumbnail: tapesPhoto14,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto1,
        thumbnail: tapesPhoto1,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto2,
        thumbnail: tapesPhoto2,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto3,
        thumbnail: tapesPhoto3,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto4,
        thumbnail: tapesPhoto4,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto5,
        thumbnail: tapesPhoto5,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto6,
        thumbnail: tapesPhoto6,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto7,
        thumbnail: tapesPhoto7,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto8,
        thumbnail: tapesPhoto8,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto9,
        thumbnail: tapesPhoto9,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto10,
        thumbnail: tapesPhoto10,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto11,
        thumbnail: tapesPhoto11,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto12,
        thumbnail: tapesPhoto12,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: tapesPhoto13,
        thumbnail: tapesPhoto13,
        thumbnailClass: "p-page-1__thumbnail",
      },
    ],
    souvenirs: [
      {
        original: souvenirsPhoto49,
        thumbnail: souvenirsPhoto49,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto41,
        thumbnail: souvenirsPhoto41,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto42,
        thumbnail: souvenirsPhoto42,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto43,
        thumbnail: souvenirsPhoto43,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto44,
        thumbnail: souvenirsPhoto44,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto45,
        thumbnail: souvenirsPhoto45,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto46,
        thumbnail: souvenirsPhoto46,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto47,
        thumbnail: souvenirsPhoto47,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto48,
        thumbnail: souvenirsPhoto48,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto35,
        thumbnail: souvenirsPhoto35,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto36,
        thumbnail: souvenirsPhoto36,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto37,
        thumbnail: souvenirsPhoto37,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto38,
        thumbnail: souvenirsPhoto38,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto39,
        thumbnail: souvenirsPhoto39,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto40,
        thumbnail: souvenirsPhoto40,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto34,
        thumbnail: souvenirsPhoto34,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto30,
        thumbnail: souvenirsPhoto30,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto31,
        thumbnail: souvenirsPhoto31,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto32,
        thumbnail: souvenirsPhoto32,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto33,
        thumbnail: souvenirsPhoto33,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto28,
        thumbnail: souvenirsPhoto28,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto29,
        thumbnail: souvenirsPhoto29,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto1,
        thumbnail: souvenirsPhoto1,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto2,
        thumbnail: souvenirsPhoto2,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto3,
        thumbnail: souvenirsPhoto3,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto4,
        thumbnail: souvenirsPhoto4,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto5,
        thumbnail: souvenirsPhoto5,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto6,
        thumbnail: souvenirsPhoto6,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto7,
        thumbnail: souvenirsPhoto7,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto8,
        thumbnail: souvenirsPhoto8,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto9,
        thumbnail: souvenirsPhoto9,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto10,
        thumbnail: souvenirsPhoto10,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto11,
        thumbnail: souvenirsPhoto11,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto12,
        thumbnail: souvenirsPhoto12,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto13,
        thumbnail: souvenirsPhoto13,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto14,
        thumbnail: souvenirsPhoto14,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto15,
        thumbnail: souvenirsPhoto15,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto16,
        thumbnail: souvenirsPhoto16,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto17,
        thumbnail: souvenirsPhoto17,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto18,
        thumbnail: souvenirsPhoto18,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto19,
        thumbnail: souvenirsPhoto19,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto20,
        thumbnail: souvenirsPhoto20,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto21,
        thumbnail: souvenirsPhoto21,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto22,
        thumbnail: souvenirsPhoto22,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto23,
        thumbnail: souvenirsPhoto23,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto24,
        thumbnail: souvenirsPhoto24,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto25,
        thumbnail: souvenirsPhoto25,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto26,
        thumbnail: souvenirsPhoto26,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: souvenirsPhoto27,
        thumbnail: souvenirsPhoto27,
        thumbnailClass: "p-page-1__thumbnail",
      },
    ],
    aprons: [
      {
        original: apronsPhoto22,
        thumbnail: apronsPhoto22,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto23,
        thumbnail: apronsPhoto23,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto24,
        thumbnail: apronsPhoto24,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto25,
        thumbnail: apronsPhoto25,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto21,
        thumbnail: apronsPhoto21,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto1,
        thumbnail: apronsPhoto1,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto2,
        thumbnail: apronsPhoto2,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto3,
        thumbnail: apronsPhoto3,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto4,
        thumbnail: apronsPhoto4,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto5,
        thumbnail: apronsPhoto5,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto6,
        thumbnail: apronsPhoto6,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto7,
        thumbnail: apronsPhoto7,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto8,
        thumbnail: apronsPhoto8,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto9,
        thumbnail: apronsPhoto9,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto10,
        thumbnail: apronsPhoto10,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto11,
        thumbnail: apronsPhoto11,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto12,
        thumbnail: apronsPhoto12,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto13,
        thumbnail: apronsPhoto13,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto14,
        thumbnail: apronsPhoto14,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto15,
        thumbnail: apronsPhoto15,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto16,
        thumbnail: apronsPhoto16,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: apronsPhoto17,
        thumbnail: apronsPhoto17,
        thumbnailClass: "p-page-1__thumbnail",
      },
    ],
    bags: [
      {
        original: bagsPhoto11,
        thumbnail: bagsPhoto11,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: bagsPhoto12,
        thumbnail: bagsPhoto12,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: bagsPhoto13,
        thumbnail: bagsPhoto13,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: bagsPhoto14,
        thumbnail: bagsPhoto14,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: bagsPhoto15,
        thumbnail: bagsPhoto15,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: bagsPhoto1,
        thumbnail: bagsPhoto1,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: bagsPhoto2,
        thumbnail: bagsPhoto2,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: bagsPhoto3,
        thumbnail: bagsPhoto3,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: bagsPhoto4,
        thumbnail: bagsPhoto4,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: bagsPhoto5,
        thumbnail: bagsPhoto5,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: bagsPhoto6,
        thumbnail: bagsPhoto6,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: bagsPhoto7,
        thumbnail: bagsPhoto7,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: bagsPhoto8,
        thumbnail: bagsPhoto8,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: bagsPhoto9,
        thumbnail: bagsPhoto9,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: bagsPhoto10,
        thumbnail: bagsPhoto10,
        thumbnailClass: "p-page-1__thumbnail",
      },
    ],
    presents: [
      {
        original: presentsPhoto39,
        thumbnail: presentsPhoto39,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto40,
        thumbnail: presentsPhoto40,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto41,
        thumbnail: presentsPhoto41,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto42,
        thumbnail: presentsPhoto42,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto43,
        thumbnail: presentsPhoto43,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto44,
        thumbnail: presentsPhoto44,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto45,
        thumbnail: presentsPhoto45,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto46,
        thumbnail: presentsPhoto46,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto47,
        thumbnail: presentsPhoto47,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto36,
        thumbnail: presentsPhoto36,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto37,
        thumbnail: presentsPhoto37,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto38,
        thumbnail: presentsPhoto38,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto34,
        thumbnail: presentsPhoto34,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto35,
        thumbnail: presentsPhoto35,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto1,
        thumbnail: presentsPhoto1,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto2,
        thumbnail: presentsPhoto2,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto3,
        thumbnail: presentsPhoto3,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto4,
        thumbnail: presentsPhoto4,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto5,
        thumbnail: presentsPhoto5,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto6,
        thumbnail: presentsPhoto6,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto7,
        thumbnail: presentsPhoto7,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto8,
        thumbnail: presentsPhoto8,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto9,
        thumbnail: presentsPhoto9,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto10,
        thumbnail: presentsPhoto10,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto11,
        thumbnail: presentsPhoto11,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto12,
        thumbnail: presentsPhoto12,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto13,
        thumbnail: presentsPhoto13,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto14,
        thumbnail: presentsPhoto14,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto15,
        thumbnail: presentsPhoto15,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto16,
        thumbnail: presentsPhoto16,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto17,
        thumbnail: presentsPhoto17,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto18,
        thumbnail: presentsPhoto18,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto19,
        thumbnail: presentsPhoto19,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto20,
        thumbnail: presentsPhoto20,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto21,
        thumbnail: presentsPhoto21,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto22,
        thumbnail: presentsPhoto22,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto23,
        thumbnail: presentsPhoto23,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto24,
        thumbnail: presentsPhoto24,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto25,
        thumbnail: presentsPhoto25,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto26,
        thumbnail: presentsPhoto26,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto27,
        thumbnail: presentsPhoto27,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto28,
        thumbnail: presentsPhoto28,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: presentsPhoto29,
        thumbnail: presentsPhoto29,
        thumbnailClass: "p-page-1__thumbnail",
      },
    ],
    polygraphy: [
      {
        original: polygraphyPhoto37,
        thumbnail: polygraphyPhoto37,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto1,
        thumbnail: polygraphyPhoto1,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto2,
        thumbnail: polygraphyPhoto2,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto3,
        thumbnail: polygraphyPhoto3,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto4,
        thumbnail: polygraphyPhoto4,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto5,
        thumbnail: polygraphyPhoto5,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto6,
        thumbnail: polygraphyPhoto6,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto7,
        thumbnail: polygraphyPhoto7,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto8,
        thumbnail: polygraphyPhoto8,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto9,
        thumbnail: polygraphyPhoto9,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto10,
        thumbnail: polygraphyPhoto10,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto11,
        thumbnail: polygraphyPhoto11,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto12,
        thumbnail: polygraphyPhoto12,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto13,
        thumbnail: polygraphyPhoto13,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto14,
        thumbnail: polygraphyPhoto14,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto15,
        thumbnail: polygraphyPhoto15,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto16,
        thumbnail: polygraphyPhoto16,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto17,
        thumbnail: polygraphyPhoto17,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto18,
        thumbnail: polygraphyPhoto18,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto19,
        thumbnail: polygraphyPhoto19,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto20,
        thumbnail: polygraphyPhoto20,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto21,
        thumbnail: polygraphyPhoto21,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto22,
        thumbnail: polygraphyPhoto22,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto23,
        thumbnail: polygraphyPhoto23,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto24,
        thumbnail: polygraphyPhoto24,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto25,
        thumbnail: polygraphyPhoto25,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto26,
        thumbnail: polygraphyPhoto26,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto27,
        thumbnail: polygraphyPhoto27,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto28,
        thumbnail: polygraphyPhoto28,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto29,
        thumbnail: polygraphyPhoto29,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto30,
        thumbnail: polygraphyPhoto30,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto31,
        thumbnail: polygraphyPhoto31,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto32,
        thumbnail: polygraphyPhoto32,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto33,
        thumbnail: polygraphyPhoto33,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto34,
        thumbnail: polygraphyPhoto34,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto35,
        thumbnail: polygraphyPhoto35,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: polygraphyPhoto36,
        thumbnail: polygraphyPhoto36,
        thumbnailClass: "p-page-1__thumbnail",
      },
    ],
    interior: [
      {
        original: interiorPhoto27,
        thumbnail: interiorPhoto27,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto25,
        thumbnail: interiorPhoto25,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto26,
        thumbnail: interiorPhoto26,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto1,
        thumbnail: interiorPhoto1,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto2,
        thumbnail: interiorPhoto2,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto3,
        thumbnail: interiorPhoto3,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto4,
        thumbnail: interiorPhoto4,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto5,
        thumbnail: interiorPhoto5,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto6,
        thumbnail: interiorPhoto6,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto7,
        thumbnail: interiorPhoto7,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto8,
        thumbnail: interiorPhoto8,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto9,
        thumbnail: interiorPhoto9,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto10,
        thumbnail: interiorPhoto10,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto11,
        thumbnail: interiorPhoto11,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto12,
        thumbnail: interiorPhoto12,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto13,
        thumbnail: interiorPhoto13,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto14,
        thumbnail: interiorPhoto14,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto15,
        thumbnail: interiorPhoto15,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto16,
        thumbnail: interiorPhoto16,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto17,
        thumbnail: interiorPhoto17,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto18,
        thumbnail: interiorPhoto18,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto19,
        thumbnail: interiorPhoto19,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto20,
        thumbnail: interiorPhoto20,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto21,
        thumbnail: interiorPhoto21,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto22,
        thumbnail: interiorPhoto22,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto23,
        thumbnail: interiorPhoto23,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: interiorPhoto24,
        thumbnail: interiorPhoto24,
        thumbnailClass: "p-page-1__thumbnail",
      },
    ],
    dtf: [
      {
        original: dtfPhoto1,
        thumbnail: dtfPhoto1,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: dtfPhoto2,
        thumbnail: dtfPhoto2,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: dtfPhoto3,
        thumbnail: dtfPhoto3,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: dtfPhoto5,
        thumbnail: dtfPhoto5,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: dtfPhoto6,
        thumbnail: dtfPhoto6,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: dtfPhoto7,
        thumbnail: dtfPhoto7,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: dtfPhoto8,
        thumbnail: dtfPhoto8,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: dtfPhoto9,
        thumbnail: dtfPhoto9,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: dtfPhoto10,
        thumbnail: dtfPhoto10,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: dtfPhoto11,
        thumbnail: dtfPhoto11,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: dtfPhoto12,
        thumbnail: dtfPhoto12,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: dtfPhoto13,
        thumbnail: dtfPhoto13,
        thumbnailClass: "p-page-1__thumbnail",
      },
    ],
    needle: [
      {
        original: needlePhoto1,
        thumbnail: needlePhoto1,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: needlePhoto2,
        thumbnail: needlePhoto2,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: needlePhoto3,
        thumbnail: needlePhoto3,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: needlePhoto4,
        thumbnail: needlePhoto4,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: needlePhoto5,
        thumbnail: needlePhoto5,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: needlePhoto6,
        thumbnail: needlePhoto6,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: needlePhoto7,
        thumbnail: needlePhoto7,
        thumbnailClass: "p-page-1__thumbnail",
      },
      {
        original: needlePhoto8,
        thumbnail: needlePhoto8,
        thumbnailClass: "p-page-1__thumbnail",
      }, 
    ],
  };

  useEffect(() => {
    axios
      .post(`/instagram`, { accountId: "indigoprintsamara" })
      .then((fetchData) => {
        let instagramRegExp = new RegExp(
          /<script type="text\/javascript">window\._sharedData = (.*);<\/script>/
        );
        let values = fetchData.data.match(instagramRegExp)[1];
        let parsedResponse = JSON.parse(values);
        let userData = parsedResponse.entry_data.ProfilePage[0].graphql.user;
        setInstUserData(userData);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="app">
      <YMInitializer
        accounts={[72972805]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
        }}
      />

      <Router>
        <div className="page">
          <Header4 />

          <div className="page__content">
            <Switch>
              <Route path="/promotion">
                <ProductPage
                  title="Капсульные коллекции"
                  text={
                    <>
                      На вас надет шикарный дождевик, шею обнимает ласковый шелковый шарф, вы опираетесь на солидный зонт-трость, а на плече - стильный шоппер. Все изделия сочетаются друг с другом по цвету, рисунку и стилю! Красиво? Красота реальна, если вы закажете у нас все изделия капсульной коллекции и пойдете украшать мир!<br/><br/>Можно приобрести изделия с дизайном от нашей любимой самарской художницы Евгении Тарасовой, а можно «надизайнерить» что-то свое, индивидуальное, как «ни у кого».
                    </>
                  }
                  photos={productPhotos.promotion}
                />
              </Route>

              <Route path="/flags">
                <ProductPage
                  title="Флаги и вымпела"
                  text={
                    <>
                      Флаг – символ успеха компании, подчеркивающий статус и
                      респектабельность.
                      <br />
                      <br />
                      Производим:
                      <ul style={{ margin: 0 }}>
                        <li>транспаранты и перетяжки</li>
                        <li>флажки настольные</li>
                        <li>флаги мобильные (виндеры)</li>
                        <li>флажные ленты</li>
                        <li>сигнальные флаги</li>
                        <li>знамена</li>
                        <li>штандарты</li>
                      </ul>
                      <br />
                      Ткани для уличных флагов: таффета, флажная сетка, флажная
                      сетка для сквозной печати, атлас на сетке, оксфрорд.
                      Внутри помещений используются флаги из атласа, сатена и
                      мокрого шелка.
                      <br />
                      Флаги изготавливаются в вариантах: односторонний,
                      двухсторонний, из флажной сетки со сквозной печатью.
                      <br />
                      Знамена и штандарты исполняются в двухстороннем варианте,
                      оформляются бахромой и знаменными кистями.
                      <br />
                      <br />
                      Вымпел – элемент корпоративной символики используется в
                      качестве особой награды за достижения и успехи, широко
                      применяется при проведении спортивных, корпоративных и
                      массовых мероприятий.
                      <br />
                      Стандартные вымпела имеют пятиугольную форму, жесткую или
                      гнущуюся основу, при желании оформляются бахромой,
                      декоративным шнуром и кистью.
                    </>
                  }
                  photos={productPhotos.flags}
                />
              </Route>

              <Route path="/clothes">
                <ProductPage
                  title="Одежда"
                  text={
                    <>
                      Персонализируем и изготавливаем:
                      <ul style={{ margin: 0 }}>
                        <li>футболки</li>
                        <li>футбольную и баскетбольную форму</li>
                        <li>ветровки, дождевики</li>
                        <li>шапки и шарфы</li>
                        <li>свитшоты и худи</li>
                      </ul>
                      <br />
                      Нас очень любят дизайнеры: наносим индивидуальный принт на
                      ткани, воплощаем задуманные «фишки» в изделиях.
                    </>
                  }
                  photos={productPhotos.clothes}
                />
              </Route>

              <Route path="/accessories">
                <ProductPage
                  title="Аксессуары"
                  text={
                    <>
                      Шейные аксессуары подчеркивают вкус, имидж, статус и
                      корпоративную принадлежность сотрудников. Применяются в
                      качестве бизнес и личных подарков, выражающих
                      индивидуальный стиль.
                      <br />
                      При производстве шейных аксессуаров используются тактильно
                      приятные ткани высокого качества: армани шелк ,
                      креп-сатин, шифон, стрейч-шифон, шармус люкс, дешайн,
                      атлас. В качестве бюджетного варианта, например для
                      проведения промо- акций, можно использовать таффету.
                    </>
                  }
                  photos={productPhotos.accessories}
                />
              </Route>

              <Route path="/tapes">
                <ProductPage
                  title="Ленты"
                  text={
                    <>
                      Ленты для бейджей изготавливаются из специальной
                      полиэстровой ленты для двухсторонней печати. В качестве
                      крепления предлагаем: карабин, клипсу, ретрактор.
                      <br />
                      Наградные ленты для торжественных мероприятий
                      изготавливаем из тканей атлас, сатен или таффета. Лента по
                      периметру обрабатывается оверлоком или терморезом Ленты
                      атласные с печатью используются при производстве бирок для
                      одежды, в качестве декора и при оформлении подарков.
                      <br />
                      Также производим: ленты для медалей с индивидуальным
                      дизайном, ленты триколор, георгиевские ленты.
                    </>
                  }
                  photos={productPhotos.tapes}
                />
              </Route>

              <Route path="/souvenirs">
                <ProductPage
                  title="Сувениры"
                  text={
                    <>
                      Наша компания является партнером ведущих сувенирных
                      каталогов, представленных в России. Промо-сувениры и
                      бизнес-сувениры персонализируем методом тампопечати, уф
                      печати, шелкографии, блинтового тиснения, лазерной
                      гравировки и сублимации. Изготавливаем эксклюзивные и
                      классические сувениры в соответствии с целями и бюджетом
                      заказчика.
                    </>
                  }
                  photos={productPhotos.souvenirs}
                />
              </Route>

              <Route path="/aprons">
                <ProductPage
                  title="Фартуки и жилеты"
                  text={
                    <>
                      Производим фартуки классических и современных моделей.
                      Возможна разработка модели по вашему заказу! Популярные
                      ткани для фартуков: оксфорд, таффета, габардин. В
                      производстве используются как гладкокрашенные ткани, так и
                      ткани с сублимационной печатью, возможны их комбинации.
                      <br />
                      Персонализируем готовые жилеты или отшиваем на заказ:
                      <br />
                      промо жилеты (манишки): таффета, оксфорд утепленные: флис,
                      дьюспо, оксфорд, наполнение синтепух корпортативные:
                      габардин, атлас, сатен, мокрый шелк защитные сигнальные:
                      оксфорд, светоотражающие элементы спортивные номера и
                      накидки: ложная спортивная сетка, таффета
                    </>
                  }
                  photos={productPhotos.aprons}
                />
              </Route>

              <Route path="/bags">
                <ProductPage
                  title="Сумки"
                  text={
                    <>
                      Популярные шопперы, стильные рюкзаки и элегантные
                      косметички, а также: сумки холодильники,
                      сумки-трансформеры, чехлы для чемоданов и мешочки для
                      упаковки подарков. Возможна разработка модели или линейки
                      продуктов под ваши задачи и цели!
                    </>
                  }
                  photos={productPhotos.bags}
                />
              </Route>

              <Route path="/presents">
                <ProductPage
                  title="Подарки"
                  text={
                    <>
                      Согласитесь, приятно получать эксклюзивный, адресованный
                      лично тебе подарок, а дарить такой подарок вдвойне
                      приятней! Вы можете создать свой принт, а мы реализуем его
                      в текстильном изделии или нанесем на выбранный вами
                      сувенир!
                    </>
                  }
                  photos={productPhotos.presents}
                />
              </Route>

              <Route path="/polygraphy">
                <ProductPage
                  title="Полиграфия"
                  text={
                    <>
                      Нам очень нравится создавать дизайн эксклюзивных открыток,
                      также мы любим необычный дизайн календарей. С упоением
                      сочетаем различные виды и фактуры дизайнерской бумаги и
                      обожаем радоваться «вау-эмоциям» наших заказчиков.
                      Качественно и быстро производим классическую полиграфию:
                      визитки, буклеты, флаеры и т.д
                    </>
                  }
                  photos={productPhotos.polygraphy}
                />
              </Route>

              <Route path="/interior">
                <ProductPage
                  title="Интерьерная печать"
                  text={
                    <>
                      Картины, фотографии, интерьерные панно и постеры, шторы и
                      жалюзи, световые панели (лайтбоксы) и выставочные стенды
                      для дома и бизнес-идей. В качестве дополнительной опции
                      изделия оформляем в багет или подрамник.
                    </>
                  }
                  photos={productPhotos.interior}
                />
              </Route>

              <Route path="/dtf">
                <ProductPage
                  title="DTF"
                  text={
                    <>
                      DTF печать - это изготовление полноцветных наклеек (DTF
                      стикеров), которые затем наносятся на готовое изделие.
                      <br />
                      <br />
                      Преимущества DTF печати перед термотрансфером,
                      шелкографией и прямой печатью:
                      <ol>
                        <li>
                          Не требует специальной подготовки поверхности изделия
                          под нанесение.
                        </li>
                        <li>
                          Не требует специальных форм, пленок. Картинка
                          печатается и наносится за несколько минут.
                        </li>
                        <li>Широкий диапазон материалов под нанесение.</li>
                        <li>
                          Простота изготовления стикера и легкость нанесения
                          изображения позволяют быстро брендировать большие
                          партии изделий.
                        </li>
                        <li>Печать и нанесение от 1 шт.</li>
                        <li>
                          В одном заказе могут быть абсолютно разные принты, на
                          стоимости тиража это не отразиться!
                        </li>
                        <li>
                          Стикеры можно печатать «с запасом» и наносить по мере
                          необходимости. Срок годности стикера более 3 месяцев.
                        </li>
                        <li>
                          Стоимость полноцветных DTF стикеров при общем тираже
                          от 2 п.м гораздо выгоднее, чем стоимость шелкографии и
                          прямой печати!
                        </li>
                      </ol>
                      Рисунок в зеркальном отображении печатается на специальной
                      прозрачной PET пленке устойчивой к нагреванию. Далее на
                      оборотную сторону изображения наносится клеевой слой и
                      сушится. DTF стикер наносят на изделие (одежде, сумке,
                      бейсболке и т.д.) с помощью термопресса. Температура
                      нанесения 155-160 градусов С, время 10 сек. При нагревании
                      клей прочно сцепляется с поверхностью ткани и проникает в
                      волокно, тянется, нагревается и остывает вместе с ним, что
                      делает нанесение долговечным.
                      <br />
                      <br />
                      DTF печать — полноцветная. Любой рисунок, фото, текст
                      будет выглядеть как в макете. Дизайн изображения с любым
                      сочетанием цветов не влияет на стоимость стикера. Плотная
                      белая подложка позволяет наносить изображения на темные
                      ткани, при этом белый фон не выступает за границы рисунка.
                      Так как DTF стикеры печатаются на принтере, то возможная
                      мельчайшая детализация изображения: толщина линии
                      получается минимальной, и при переносе ничего не
                      пострадает.
                      <br />
                      <br />
                      DTF отлично наносится на различные виды текстиля, кожзам,
                      войлок, полимерные материалы. Главное требование —
                      стойкость материала к температуре нагрева.
                      <br />
                      <br />
                      Приезжайте к нам в офис, дадим стикеры потестить!
                    </>
                  }
                  photos={productPhotos.dtf}
                />
              </Route>

              <Route path="/needle">
                <ProductPage
                  title="Вышивка"
                  text={
                    <>
                      Вышивка - самый эстетичный способ украшения изделия.
                      Объемная фактура и аккуратно уложенные стежки придают вещи
                      изысканность, оригинальность и бОльшую ценность. Вышитый
                      рисунок тактильно приятен на ощупь и притягивает взгляд.
                      <br />
                      <br />
                      Вышивкой можно украсить как непосредственно само изделие,
                      так и нашить вышивной шеврон. В некоторых случаях
                      персонализация вышивными шевронами предпочтительнее.
                      <br />
                      <br />
                      Кроме того, вышивка долговечна по сравнению с иными
                      способами нанесения изображения, более устойчива к стиркам
                      и выгоранию. Также вышивка незаменима при брендировании
                      текстильных изделий с ворсом.
                      <br />
                      <br />
                      Для размещения заказа необходимо предоставить:
                      <ul>
                        <li>макет изображения в векторной программе;</li>
                        <li>файл-превью;</li>
                        <li>размер нанесения и тираж.</li>
                      </ul>
                      Цена на вышивку зависит от нескольких показателей:
                      <ul>
                        <li>размера;</li>
                        <li>количества и плотности стежков;</li>
                        <li>количества используемых цветов;</li>
                        <li>вида используемых нитей;</li>
                        <li>фактуры ткани под вышивку;</li>
                        <li>
                          доступности места расположения вышивки на изделии;
                        </li>
                        <li>тиража;</li>
                        <li>срочности исполнения заказа.</li>
                      </ul>
                      Соблазняйтесь вышивкой! Сделайте свой мерч «люксовым»!
                    </>
                  }
                  photos={productPhotos.needle}
                />
              </Route>

              <Route path="">
                <MainPage instUserData={instUserData} />
              </Route>
            </Switch>
          </div>

          <Footer1 />
        </div>
      </Router>
    </div>
  );
}

export default App;
