import React from "react";
import "./footer-1.css";

import classNames from "classnames";

import bg from "../../../files/background.png";
import footerLogo from "./files/footer-logo.png";
import { useHistory } from "react-router-dom";
import {HeaderButton} from "../../../components/Header/HeaderMenu/HeaderMenu";

export default function Footer1({ className }) {
  const browserHistory = useHistory();

  function scrollTo(elementId) {
    window.scrollTo({
      top: document.getElementById(elementId).offsetTop,
      behavior: "smooth",
    });
  }

  return (
    <div className={classNames("block footer-1", className)}>
      <div className="block__bg-wrapper features__bg-wrapper">
        <img className="block__bg" src={bg} alt="background" />
      </div>

      <div className="footer-1__content">
        <div className="footer-1__logo">
          <img src={footerLogo} />
        </div>

        <div className="footer-1__menu">
          <HeaderButton
            className="footer-1__menu-button"
            onClick={() => {
              if (browserHistory.location.pathname === "/") {
                scrollTo("about-us");
              }
            }}
            to={(location) => {
              return {
                ...location,
                pathname: "/",
                state: { offsetTop: "about-us", fromMain: false },
              };
            }}
          >
            О нас
          </HeaderButton>

          <HeaderButton
            className="footer-1__menu-button"
            onClick={() => {
              if (browserHistory.location.pathname === "/") {
                scrollTo("products");
              }
            }}
            to={(location) => {
              return {
                ...location,
                pathname: "/",
                state: { offsetTop: "products", fromMain: false },
              };
            }}
          >
            Продукция
          </HeaderButton>

          <HeaderButton
            className="footer-1__menu-button"
            onClick={() => {
              if (browserHistory.location.pathname === "/") {
                scrollTo("partners");
              }
            }}
            to={(location) => {
              return {
                ...location,
                pathname: "/",
                state: { offsetTop: "partners", fromMain: false },
              };
            }}
          >
            Наши партнёры
          </HeaderButton>

          <HeaderButton
            className="footer-1__menu-button"
            onClick={() => {
              if (browserHistory.location.pathname === "/") {
                scrollTo("contact-form");
              }
            }}
            to={(location) => {
              return {
                ...location,
                pathname: "/",
                state: { offsetTop: "contact-form", fromMain: false },
              };
            }}
          >
            Сделать заказ
          </HeaderButton>

          <HeaderButton
            className="footer-1__menu-button"
            onClick={() => {
              if (browserHistory.location.pathname === "/") {
                scrollTo("contacts");
              }
            }}
            to={(location) => {
              return {
                ...location,
                pathname: "/",
                state: { offsetTop: "contacts", fromMain: false },
              };
            }}
          >
            Контакты
          </HeaderButton>
        </div>
      </div>
    </div>
  );
}
