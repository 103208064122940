import React from "react";
import "./HeaderMenu.css";

import { Link } from "react-router-dom";
import classNames from "classnames";

export function HeaderLink({ className, children, ...otherProps }) {
  return (
    <a className={classNames("header-link", className)} {...otherProps}>
      {children}
    </a>
  );
}

export function HeaderButton({ className, children, ...otherProps }) {
  return (
    <Link className={classNames("header-link", className)} {...otherProps}>
      {children}
    </Link>
  );
}

export default function HeaderMenu({ className, children }) {
  return <div className={classNames("header-menu", className)}>{children}</div>;
}
