import React from "react";
import "./features-4.css";

import classNames from "classnames";

import cell1Icon from "./files/cell-1-icon.png";
import cell2Icon from "./files/cell-2-icon.png";
import cell3Icon from "./files/cell-3-icon.png";
import cell4Icon from "./files/cell-4-icon.png";
import cell5Icon from "./files/cell-5-icon.png";
import cell6Icon from "./files/cell-6-icon.png";
import cell7Icon from "./files/cell-7-icon.png";
import cell8Icon from "./files/cell-8-icon.png";
import cell9Icon from "./files/cell-9-icon.png";
import cell10Icon from "./files/cell-10-icon.png";

export default function Features4({ className, ...otherProps }) {
  return (
    <div className={classNames("block features-4", className)} {...otherProps}>
      <div className="block__content_column features-4__content">
        <h2 className="features-4__header">Наши партнеры</h2>

        <div className="features-4__grid">
          <div className="features-4__cell">
            <img src={cell1Icon} />
          </div>

          <div className="features-4__cell">
            <img src={cell2Icon}/>
          </div>

          <div className="features-4__cell">
            <img src={cell3Icon}/>
          </div>

          <div className="features-4__cell">
            <img src={cell4Icon}/>
          </div>

          <div className="features-4__cell">
            <img src={cell5Icon}/>
          </div>

          <div className="features-4__cell">
            <img src={cell6Icon}/>
          </div>

          <div className="features-4__cell">
            <img src={cell7Icon}/>
          </div>

          <div className="features-4__cell">
            <img src={cell8Icon}/>
          </div>

          <div className="features-4__cell">
            <img src={cell9Icon}/>
          </div>

          <div className="features-4__cell">
            <img src={cell10Icon}/>
          </div>
        </div>
      </div>
    </div>
  );
}
