import React from "react";
import "./about-us.css";

import classNames from "classnames";
import useWindowSize from "../../hooks/useWindowSize";

import Button from "../../components/Button/Button";

export default function AboutUs({ alignment, ...otherProps }) {
  const [windowWidth] = useWindowSize();

  return (
    <div className={classNames("block about-us", alignment)} {...otherProps}>
      <div
        className={classNames("block__content_column about-us__content_column")}
      >
        <h2 className={classNames("about-us__heading")}>О нас</h2>

        <div className="about-us__text-block">
          <p className="text_body about-us__text">
            Компания <b>«Индиго-Принт»</b> — профессиональная текстильная
            типография, {windowWidth > 1070 && <br />} <b>создана</b> нами{" "}
            <b>в 2007 году</b>. Производим: флажную продукцию, рекламный,
            интерьерный, корпоративный и спортивный текстиль, оказываем услуги
            по полиграфии и брендируем сувенирную продукцию.
          </p>

          <p className="text_body about-us__text">
            Компания пережила два экономических кризиса и с успехом наращивает
            производственные мощности. Индивидуально подходим к каждому заказу и
            <b> гордимся</b> обширной <b>клиентской базой</b>.
          </p>

          <p className="text_body about-us__text">
            <b>Работаем единой командой</b> более десяти лет и дорожим каждым
            {windowWidth > 1070 && <br />}
            сотрудником. Наша дизайн-студия воплощает самые смелые идеи
            заказчиков, опытные технологи и швеи реализуют задуманное в
            материале.
          </p>

          <p className="text_body about-us__text">
            <b>Нас ценят</b> за то, что <b>мы выручаем</b> когда: «надо было
            вчера», «мне только одну», {windowWidth > 1070 && <br />}
            «1000 и 1 штуку завтра после обеда», «надо такой же, только с
            перламутровыми пуговками».
          </p>
        </div>

        <div className="about-us__buttons-block">
          <a href="https://instagram.com/indigoprintsamara" target="_blank">
            <Button className="about-us__button-first">Наш инстаграм</Button>
          </a>
        </div>
      </div>
    </div>
  );
}
