export function email(email, errorText = "Ошибка в поле") {
  // eslint-disable-next-line
  var regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!regexp.test(String(email).toLowerCase())) {
    return {error: true, errorText: errorText};
  }

  return false;
}

export function password(
  password,
  minLength = 0,
  maxLength = Infinity,
  regexp = false,
  errorText = "Ошибка в поле"
) {
  if (password.length < minLength) {
    return {error: true, errorText: errorText};
  }
  if (password.length > maxLength) {
    return {error: true, errorText: errorText};
  }
  if (regexp) {
    return !regexp.test(String(password));
  }

  return false;
}

export function tel(tel, errorText = "Ошибка в поле") {
  let regexp = /^([+]?\d{1,2}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{4}$/;

  if (!regexp.test(tel)) {
    return {error: true, errorText: errorText};
  } else {
    return false;
  }
}

export function text(
  value,
  minLength = 0,
  maxLength = Infinity,
  regexp = false,
  errorText = "Ошибка в поле"
) {
  if (value.length < minLength) {
    return {error: true, errorText: errorText};
  }
  if (value.length > maxLength) {
    return {error: true, errorText: errorText};
  }
  if (regexp && !regexp.text(String(value))) {
    return {error: true, errorText: errorText};
  }

  return false;
}
