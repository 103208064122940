import React, { useState } from "react";
import "./header-4.css";
import "../header.css";

import classNames from "classnames";
import { useHistory } from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import { Link, withRouter, matchPath } from "react-router-dom";

import HeaderMenu, {
  HeaderButton,
  HeaderLink,
} from "../../../components/Header/HeaderMenu/HeaderMenu";
import MenuFullScreen from "../../menu/full-screen/2/menu-full-screen-2";

import burgerIcon from "../files/header__burger-icon.png";
import logo from "./files/logo.png";
// import logo from "./files/logo.svg";
import instIcon from "./files/inst-icon.svg";
import vkIcon from "./files/vk-icon.svg";
import bannerPc from "../../../files/promotion/banner_pc.jpg";
import bannerMobile from "../../../files/promotion/banner_mobile.jpg";

export default function Header4({ className, history }) {
  const [windowWidth] = useWindowSize();
  const [mobMenuOpened, setMobMenuOpened] = useState(false);
  const browserHistory = useHistory();

  function scrollTo(elementId) {
    window.scrollTo({
      top: document.getElementById(elementId).offsetTop,
      behavior: "smooth",
    });
  }

  const menuPC = (
    <HeaderMenu>
      <HeaderButton
        onClick={() => {
          if (browserHistory.location.pathname === "/") {
            scrollTo("about-us");
          }
        }}
        to={(location) => {
          return {
            ...location,
            pathname: "/",
            state: { offsetTop: "about-us", fromMain: false },
          };
        }}
      >
        О нас
      </HeaderButton>

      <HeaderButton
        onClick={() => {
          if (browserHistory.location.pathname === "/") {
            scrollTo("products");
          }
        }}
        to={(location) => {
          return {
            ...location,
            pathname: "/",
            state: { offsetTop: "products", fromMain: false },
          };
        }}
      >
        Продукция
      </HeaderButton>

      <HeaderButton
        onClick={() => {
          if (browserHistory.location.pathname === "/") {
            scrollTo("partners");
          }
        }}
        to={(location) => {
          return {
            ...location,
            pathname: "/",
            state: { offsetTop: "partners", fromMain: false },
          };
        }}
      >
        Наши партнёры
      </HeaderButton>

      <HeaderButton
        onClick={() => {
          if (browserHistory.location.pathname === "/") {
            scrollTo("contact-form");
          }
        }}
        to={(location) => {
          return {
            ...location,
            pathname: "/",
            state: { offsetTop: "contact-form", fromMain: false },
          };
        }}
      >
        Сделать заказ
      </HeaderButton>

      <HeaderButton
        onClick={() => {
          if (browserHistory.location.pathname === "/") {
            scrollTo("contacts");
          }
        }}
        to={(location) => {
          return {
            ...location,
            pathname: "/",
            state: { offsetTop: "contacts", fromMain: false },
          };
        }}
      >
        Контакты
      </HeaderButton>

      <HeaderLink
        href="https://instagram.com/indigoprintsamara"
        target="_blank"
      >
        <a>
          <img src={instIcon} />
        </a>
      </HeaderLink>

      <HeaderLink
        href="https://instagram.com/indigoprintsamara"
        target="_blank"
      >
        <a href="https://vk.com/indigo_v_samare" target="_blank">
          <img src={vkIcon} />
        </a>
      </HeaderLink>
    </HeaderMenu>
  );

  const menuMobile = (
    <HeaderMenu>
      <HeaderLink className="header-4__link">
        <a href="https://instagram.com/indigoprintsamara" target="_blank">
          <img className="header-4__social-icon" src={instIcon} />
        </a>
      </HeaderLink>

      <HeaderLink className="header-4__link">
        <a href="https://vk.com/indigo_v_samare" target="_blank">
          <img className="header-4__social-icon" src={vkIcon} />
        </a>
      </HeaderLink>

      <button
        className="header__button header__button-burger"
        onClick={() => manipulateMenu(true)}
      >
        <img src={burgerIcon} alt="" />
      </button>
    </HeaderMenu>
  );

  function manipulateMenu(status) {
    setMobMenuOpened(status);

    if (status) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }

  return (
    <>
      <div className={classNames("header header-4", className)}>
        <Link
          className="banner"
          to={(location) => {
            return {
              ...location,
              pathname: "/promotion",
              state: { fromMain: true },
            };
          }}
        >
          <img className="banner-img banner-img_pc" src={bannerPc} />

          <img className="banner-img banner-img_mobile" src={bannerMobile} />
        </Link>

        <div className="header__content">
          {/* <Link
            to={(location) => {
              return {
                ...location,
                pathname: "/",
                state: { fromMain: true },
              };
            }}
            className="header__logo"
          >
            <img src={logo} />
          </Link> */}

          {windowWidth > 960 ? menuPC : menuMobile}
        </div>
      </div>

      {windowWidth <= 960 && (
        <MenuFullScreen
          menuOpened={mobMenuOpened}
          setMobMenuOpened={() => manipulateMenu(false)}
          browserHistory={browserHistory}
        />
      )}
    </>
  );
}
