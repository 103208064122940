import React from 'react';
import './CarouselArrow.css';

import classNames from 'classnames';

export default function CarouselArrow({ rotate, className, ...otherProps }) {
  return (
    <button
      className={classNames("carousel-arrow", className)}
      {...otherProps}
    >
      <svg style={{transform: 'rotate(' + rotate + 'deg)'}} className="carousel-arrow__svg" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 1.5L1.6304 0L8 6L1.6304 12L0 10.5L4.8 6L0 1.5Z" />
      </svg>
    </button>
  )
}