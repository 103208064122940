import React, { useEffect } from "react";
import "./ProductPage.css";

import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import CarouselArrow from "../../components/CarouselArrow/CarouselArrow";

import arrow from "../../files/icons/arrow.svg";

export default function ProductPage({
  className,
  title,
  text,
  photos,
  ...otherProps
}) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const renderLeftNav = (onClick, disabled) => {
    return (
      <div className="p-page-1__left-nav">
        <CarouselArrow rotate={180} disabled={disabled} onClick={onClick} />
      </div>
    );
  };

  const renderRightNav = (onClick, disabled) => {
    return (
      <div className="p-page-1__right-nav">
        <CarouselArrow rotate={0} disabled={disabled} onClick={onClick} />
      </div>
    );
  };

  return (
    <div className={classNames("block p-page-1", className)} {...otherProps}>
      <div
        className={classNames("block__content_column p-page-1__content_column")}
      >
        <Link
          className="p-page-1__to-main"
          to={(location) => {
            return {
              ...location,
              pathname: "/",
              state: { ...location.state, fromMain: false },
            };
          }}
        >
          <div className="p-page-1__to-main-arrow">
            <img src={arrow} />
          </div>

          <h5 className="text-body p-page-1__to-main-text">
            Вернуться на главную страницу
          </h5>
        </Link>

        { photos && !!photos.length &&
          <ImageGallery
            items={photos}
            showFullscreenButton={false}
            showPlayButton={false}
            additionalClass="p-page-1__gallery"
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
          />
        }
        <h2 className={classNames("p-page-1__heading")}>{title}</h2>

        <div className="p-page-1__text-block">
          <p className="text_body p-page-1__text">{text}</p>
        </div>
      </div>
    </div>
  );
}
