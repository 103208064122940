import React from 'react';

import './Carousel.css';

import classNames from 'classnames';

function Slide({children, width}) {
  return (
    <div className="carousel__slide" style={{width: width}}>
      {children}
    </div>
  )
}

export default function Carousel({children, cardsInRow, margin}) {
  // const slideWidth = () => {
  //   let width = "calc(100% / " + cardsInRow + ")";
  //   return width;
  // }

  // const carouselRows = () => {
  //   const rowNumber = Math.ceil(children.length / cardsInRow);
  //   let slicePosition = 0;
  //   let rows = [];

  //   for (let i = 0; i < rowNumber; i++) {
  //     rows.push(children.slice(slicePosition, slicePosition + Number(cardsInRow)));
  //     slicePosition = (slicePosition + Number(cardsInRow));
  //   }

  //   const rowElement = rows.map((row) => {
  //     const slides = row.map((slide) => 
  //       <Slide width={slideWidth()}>{slide}</Slide>
  //     )

  //     return (
  //       <div className='carousel__row'>
  //         {slides}
  //       </div>
  //     )
  //   })

  //   return rowElement;
  // }

  const slides = children.map((slide, id) =>
    <Slide key={id}>{slide}</Slide>
  )

  return (
    <div className={classNames('carousel')}>
      <div className="carousel__inside">
        {slides}
      </div>
    </div>
  )
}