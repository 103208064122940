import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";

import Intro8 from "../../blocks/intro/8/intro-8";
import AboutUs from "../../blocks/about-us/about-us";
import ContactsMap1 from "../../blocks/contacts/map/1/contacts-map-1";
import Features3 from "../../blocks/features/3/features-3";
import Features4 from "../../blocks/features/4/features-4";
import ContactForm1 from "../../blocks/contact-form/1/contact-form-1";

export default function MainPage({ instUserData }) {
  let browserHistory = useHistory();

  useEffect(() => {
    const pageOffsetTop = browserHistory.location.state
      ? browserHistory.location.state.offsetTop
      : false;

    if (typeof pageOffsetTop === "string") {
      window.scrollTo({
        top: document.getElementById(pageOffsetTop).offsetTop,
        behavior: "smooth",
      });
    } else if (pageOffsetTop) {
      window.scrollTo({
        top: pageOffsetTop,
      });
    }
  }, []);

  useBeforeunload(() => {
    browserHistory.replace({
      to: browserHistory,
      state: {
        ...browserHistory.location.state,
        offsetTop: window.pageYOffset,
      },
    });
  });

  return (
    <>
      <Intro8 alignment={"intro-8_align-start"} />

      <AboutUs id="about-us" />

      <Features3 id="products" browserHistory={browserHistory} />

      <Features4 id="partners" />

      <ContactForm1 id="contact-form" instUserData={instUserData} />

      <ContactsMap1 id="contacts" instUserData={instUserData} />
    </>
  );
}
